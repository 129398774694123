<div fxLayoutAlign="center center" fxFlexFill>
    <mat-card fxFlex="30">
        <div *ngIf="isCopied">
            <p style="text-align: center;font-size: 20px;color: green;background: aliceblue;padding: 10px;"><mat-icon>check_circle_outline</mat-icon> Card copied successfully !</p>
            <mat-divider></mat-divider>
            <div style="text-align: center;">
                <button mat-raised-button color="warn" (click)="closeDialog()">Close</button>
            </div>
        </div>
        <div *ngIf="!isCopied">
            <form [formGroup]="firstFormGroup">
                <h2 style="text-align: center;">Copy Card</h2>
                <div class="form-group">                
                    <input class="form-control"  placeholder="Company Name" (change)="getUniqueSlug()" formControlName="Company_Name" required>
                </div>
                <div class="form-group">                
                    <input class="form-control"  placeholder="Page Unique Url" readonly formControlName="Company_Url" required>
                </div>
                <div class="form-group">                
                    <input class="form-control"  placeholder="Contact No" formControlName="Contact_no" required>
                </div>
                <div style="text-align: center;">
                    <button mat-raised-button color="primary" [disabled]="!firstFormGroup.valid" (click)="copyCompany()">Copy Card</button>
                </div>
            </form>
        </div>
    </mat-card>
</div>