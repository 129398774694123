import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LazyLoadEvent } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { MatDialog } from '@angular/material/dialog';
import { CopycardComponent } from 'src/app/common/copycard/copycard.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';


@Component({
  selector: 'app-cardlisting',
  templateUrl: './cardlisting.component.html',
  styleUrls: ['./cardlisting.component.css']
})
export class CardlistingComponent implements OnInit {

  allCards: any = [];
  allInitCards: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  newRespo: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  cardCount: number = 1;
  userRole: any = "Client";
  exportColumns: any[];
  serviceUrl: any;

  usersFilters: any;
  card_filter: FormGroup;
  type: any = "";

  scrollingSubscription: any;
  scrollCDK: any;

  constructor(
      private http: HttpService,
      private _router: Router,
      private dialog: MatDialog,
      private clipboard: Clipboard,
      private _snackBar: MatSnackBar,
      private _formBuilder: FormBuilder,
      private route: ActivatedRoute,
      public scroll: ScrollDispatcher,
    ) { 
        this.serviceUrl = this.http.pageUrl;
        sessionStorage.removeItem('tabNo');

        this.route.params.subscribe( 
          params => {
            if(params.type){
              this.type = params.type;
              console.log(this.type);
            }
          }
        );
        
        this.card_filter = this._formBuilder.group({
          user: '',
          status: '', 
          payment: ''
        });

        this.scrollingSubscription = this.scroll
          .scrolled()
          .subscribe((data: CdkScrollable) => {
            // this.onWindowScroll(data);
            this.scrollCDK = data;
          });
    }

  ngOnInit(): void {
    this.getDetails();
    this.initTable();
    this.cardCount = parseInt(sessionStorage.getItem('userCards'));
    this.userRole = sessionStorage.getItem('userRole');
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loading = false;

    this.getUserFilter();
  }

  filterRecord(){
      let cardFilter = this.card_filter.value;
      console.log(this.card_filter.value);
      this.allCards = this.allInitCards;
      if(cardFilter.user!='' && cardFilter.status!='' && cardFilter.payment!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.Created_by==cardFilter.user && element.is_Active==cardFilter.status && element.is_Paid==cardFilter.payment){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.user!='' && cardFilter.status!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.Created_by==cardFilter.user && element.is_Active==cardFilter.status){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.user!='' && cardFilter.payment!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.Created_by==cardFilter.user && element.is_Paid==cardFilter.payment){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.status!='' && cardFilter.payment!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.is_Paid==cardFilter.payment && element.is_Active==cardFilter.status){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.user!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.Created_by==cardFilter.user){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.payment!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.is_Paid==cardFilter.payment){
              this.allCards.push(element);
            }
        });
      }
      else if(cardFilter.status!=''){
        this.allCards = [];
        this.allInitCards.forEach(element => {
            if(element.is_Active==cardFilter.status){
              this.allCards.push(element);
            }
        });
      }
  }

  getUserFilter(){
    let formdata = new FormData();
    formdata.append('requestUser',sessionStorage.getItem('userRole'));
    formdata.append('Francisee_Id',sessionStorage.getItem('franciseeId'));
    formdata.append('userId', sessionStorage.getItem('userId'));
    this.http.postDataByForm('companies/get_user_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.usersFilters = this.respo.data;
          }
        });
  }

  initTable(){
    this.cols = [
      // { field: 'Company_Url', header: 'Id' },
      { field: 'Company_Name', header: 'Company Name' },
      { field: 'Mobile_No', header: 'Contact No' },
      { field: 'Contact_No', header: 'Created By' },
      { field: 'Register_date', header: 'Register Date' },
      { field: 'ExpiryDate', header: 'Expiry Date' }
    ];
  }
  clientPay(record: any){
    this._router.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+"/subscription/"+record.Id+"|"+record.Company_Url);
  }
  copied(record: any){
    let pageUrl = sessionStorage.getItem('mainURL')+record.Company_Url;
    this.clipboard.copy(pageUrl);
    this._snackBar.open(pageUrl, 'copied successfully.', {
      duration: 2000,
    });
  }
  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
        if (this.datasource) {
            this.allCards = this.datasource.slice(event.first, (event.first + event.rows));
            this.loading = false;
        }
    }, 1000);
}

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allCards.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  
//   exportPdf() {
//     import("jspdf").then(jsPDF => {
//         import("jspdf-autotable").then(x => {
//             const doc = new jsPDF.default(0,0);
//             doc.autoTable(this.exportColumns, this.allBills);
//             doc.save('primengTable.pdf');
//         })
//     })
// }

// exportExcel() {
//     import("xlsx").then(xlsx => {
//         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
//         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
//         this.saveAsExcelFile(excelBuffer, "primengTable");
//     });
// }

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  getDetails(){
    let formdata = new FormData();
    formdata.append('user',sessionStorage.getItem('userRole'));
    formdata.append('userId',sessionStorage.getItem('userId'));
    formdata.append('franciseeId',sessionStorage.getItem('franciseeId'));
    this.http.postDataByForm('companies/get_card_listing',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allCards = this.respo.data.records;
            this.allInitCards = this.respo.data.records;
            this.totalRecords = this.allCards.length;
          }
        });
  }
  changeStatus(card: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of company", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.changeConfirm(card);
        }
    });
  }
  changeConfirm(card: any){
    console.log(card);
    let active = card.is_Active=='0'?'1':'0';
    let formdata = new FormData();
    let firstForm = {
        Company_Name: card.Company_Name,
        Id: card.Id,
        User_Id: card.User_Id,
        Updated_by: sessionStorage.getItem('userId'),
        is_Active: active
    };
    let data = JSON.stringify(firstForm);
    formdata.append('company',data);
    this.http.postDataByForm('companies/createcompany',formdata)
            .then((data: any) => {
                if(data.status==1){
                  card.is_Active = active;
                }
                else {
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Action fail!', message: "Status not updated. Please, try after sometime.",popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                  });
                }
            });
  }
  editRecords(card: any){
    console.log(card);
    this._router.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+'/createcard/'+card.User_Id+"/"+card.Company_Url);
  }
  createCard(){
    this._router.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+"/createcard");
  }
  copycard(card: any){
    let dialogRef = this.dialog.open(CopycardComponent,{
      width: "350px",
      data: { card: JSON.stringify(card), title: 'Sure to change status!', message: "Are you sure, You want to change the status of company", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.getDetails();
        }
    });
  }
  deleteCard(card: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to delete!', message: "Are you sure to delete card, <strong>Once deleted, can not restore it.<strong>", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.deleteCompany(card);
        }
    });
  }
  deleteCompany(card: any){
    let formdata = new FormData();
    let firstForm = {
        Company_Name: card.Company_Name,
        Company_Url: '',
        Id: card.Id,
        Deleted_by: sessionStorage.getItem('userId'),
        Deleted_date: new Date('Y-m-d h:i:s')
    };
    let data = JSON.stringify(firstForm);
    formdata.append('company',data);
    this.http.postDataByForm('companies/createcompany',formdata)
            .then(data => {
                console.log(data);
                this.newRespo = data;
                if(this.newRespo.status==1){
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Action success!', message: "Company card deleted successfully.",popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.getDetails();
                  });
                }
            });
  }
}
