import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { AlertpopupComponent } from '../alertpopup/alertpopup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
  isaddMore: boolean = false;
  formGroup: FormGroup;
  respo: any;
  Business: any = [];
  response: any;
  selectedBusiness: any = [];
  manual: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private _formBuilder: FormBuilder,
    private http: HttpService,
    public dialogRef: MatDialogRef<BusinessComponent>
  ) {   
    console.log(data); 
    if(data.default!=''){
      this.selectedBusiness = JSON.parse(data.default);
    }
    if(data.manual!=''){
      this.isaddMore = true;
      this.manual = JSON.parse(data.manual);
    }
    this.formGroup = this._formBuilder.group({
      natures: this._formBuilder.array([])
    });
    this.getDetails();
  }
  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.selectedBusiness.push(e.target.value);
    } else {
      const index = this.selectedBusiness.findIndex(x => x === e.target.value);
      console.log(index);
      this.selectedBusiness.splice(index,1);
    }
    console.log(this.selectedBusiness);
  }
  callfunction(businessName: any){
    const index = this.selectedBusiness.findIndex(x => x === businessName);
    if(index>-1){
      return true;
    }
    return false;
  }
  Natures() : FormArray {
    return this.formGroup.get("natures") as FormArray
  }
  newNatures(): FormGroup {
    return this._formBuilder.group({
      businessName: ''
    })
  }
  addNewNature() {
    this.Natures().push(this.newNatures());
  }   
  removeNature(i:number) {
    this.Natures().removeAt(i);
  }
  ngOnInit(): void {
    this.formGroup.setControl('natures',this.editManual(this.manual));
  }
  editManual(manual: any[]) : FormArray{
    const formArr = new FormArray([]);
    manual.forEach(mb => {
        formArr.push(this._formBuilder.group({
          businessName: mb.businessName
        }));
      });
    return formArr;
  }
  addOther(){
      this.isaddMore = true;
      this.addNewNature();
  }
  getDetails(){
    let formdata = new FormData();
    formdata.append('table','nature_business');
    // formdata.append('field_name','businessName');
    this.http.postDataByForm('common/get_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.Business = this.respo.data.records;
          }
        });
  }
  closeCDialog(){
    let natures = JSON.stringify(this.selectedBusiness);
    let manual = JSON.stringify(this.formGroup.get('natures').value);
    let records = {'business':natures,'manual':manual};
    console.log(JSON.stringify(records));
    this.dialogRef.close(JSON.stringify(records));
  }
}
