<h2>
    {{themeLabel}}
    <mat-dialog-actions align="end">
        <button style="float: right;" mat-raised-button color="primary" (click)="dismissPopup()" ><mat-icon>highlight_off</mat-icon> Done</button>
    </mat-dialog-actions>
</h2>
<mat-divider></mat-divider>
<div *ngIf="colorList.length>0">
    <div *ngFor="let color of colorList">
        <a mat-list-item [ngStyle]="{'background-color': color.color}" [ngClass]="{'active':color.color===activeColor}" class="color-button" (click)="selectColor(color.color)"></a>
    </div>
</div>
<div style="clear: both;"></div>
<mat-divider></mat-divider>
<div>
    <form [formGroup]="colorFormGroup">
        <input type="color" style="width: 50px; height: 50px;" (change)="customColor()" formControlName="customcolor" />
    </form>
</div>