import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-createcard',
  templateUrl: './createcard.component.html',
  styleUrls: ['./createcard.component.css']
})
export class CreatecardComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fouthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixFormGroup: FormGroup;
  sevenFormGroup: FormGroup;
  eightFormGroup: FormGroup;
  nineFormGroup: FormGroup;
  tenFormGroup: FormGroup;
  elevenFormGroup: FormGroup;

  imagePath: any = "http://kheduthaatindia.in/templates/";
  themes: any = [];
  constructor(private _formBuilder: FormBuilder) {
      this.themes = [
        {
          'Theme_image': 'template1.png',
          'Theme_name' : 'basic Theme',
          'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
        },
        {
          'Theme_image': 'template2.png',
          'Theme_name' : 'basic Theme',
          'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
        },
        {
          'Theme_image': 'template3.png',
          'Theme_name' : 'basic Theme',
          'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
        },
        {
          'Theme_image': 'template4.png',
          'Theme_name' : 'basic Theme',
          'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
        },
        {
          'Theme_image': 'template5.png',
          'Theme_name' : 'basic Theme',
          'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
        }
      ];
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.fouthFormGroup = this._formBuilder.group({
      fourCtrl: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      fifthCtrl: ['', Validators.required]
    });
    this.sixFormGroup = this._formBuilder.group({
      sixCtrl: ['', Validators.required]
    });
    this.sevenFormGroup = this._formBuilder.group({
      sevenCtrl: ['', Validators.required]
    });
    this.eightFormGroup = this._formBuilder.group({
      eightCtrl: ['', Validators.required]
    });
    this.nineFormGroup = this._formBuilder.group({
      nineCtrl: ['', Validators.required]
    });
    this.tenFormGroup = this._formBuilder.group({
      tenCtrl: ['', Validators.required]
    });
    this.elevenFormGroup = this._formBuilder.group({
      elevenCtrl: ['', Validators.required]
    });
  }

}
