<div>
    <h2 class="dashboardstat">Dashboard Stat
        <button mat-raised-button color="primary" style="float: right;"><mat-icon>settings</mat-icon> PREVIEW CARD</button>
    </h2>
</div>
<mat-divider></mat-divider>
<div class="dashheader">
      <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Company Name</ng-template>
            <mat-form-field>
              <mat-label>Company Name</mat-label>
              <input matInput placeholder="Company Name" formControlName="firstCtrl" required>
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Theme Selection</ng-template>
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <div fxLayout="row" *ngIf="themes.length>0" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
              <mat-card fxFlex="20" *ngFor="let theme of themes" class="relativeClass">
                <div style="margin-left: -15px; margin-top: -15px; margin-bottom: 10px; margin-right: -15px;">
                  <button mat-raised-button color="primary" style="width: 48%; margin-right: 2%;"><mat-icon>brush</mat-icon> Colors</button>
                  <button mat-raised-button color="accent" style="width: 48%; margin-left: 2%;"><mat-icon>remove_red_eye</mat-icon> Preview</button>
                </div>
                <div class="selectedTheme"></div>
                <img mat-card-image [src]="imagePath+theme.Theme_image" style="max-height:420px;" alt="Photo of a Shiba Inu">
                <mat-card-header>
                  <mat-card-title>{{theme.Theme_name}}</mat-card-title>
                </mat-card-header>
              </mat-card>
          </div>
          <br><br>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-raised-button color="primary" matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup">
              <ng-template matStepLabel>Basic Details</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="fouthFormGroup">
            <form [formGroup]="fouthFormGroup">
              <ng-template matStepLabel>Links</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="fourCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="fifthFormGroup">
            <form [formGroup]="fifthFormGroup">
              <ng-template matStepLabel>Social Links</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="fifthCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="sixFormGroup">
            <form [formGroup]="sixFormGroup">
              <ng-template matStepLabel>About Us</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="sixCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="sevenFormGroup">
            <form [formGroup]="sevenFormGroup">
              <ng-template matStepLabel>Products/Services</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="sevenCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="eightFormGroup">
            <form [formGroup]="eightFormGroup">
              <ng-template matStepLabel>Payment Options (Optional)</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="eightCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="nineFormGroup">
            <form [formGroup]="nineFormGroup">
              <ng-template matStepLabel>Photo Gallery</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="nineCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="tenFormGroup">
            <form [formGroup]="tenFormGroup">
              <ng-template matStepLabel>YouTube Videos (Optional)</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="tenCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="elevenFormGroup">
            <form [formGroup]="elevenFormGroup">
              <ng-template matStepLabel>Settings</ng-template>
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="elevenCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>      
</div>