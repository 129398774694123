import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  userFormgroup: FormGroup;
  userData: any;
  editId: any;
  inProgress: any;
  response: any;
  userRole: any = "Client";
  franchisee: any;
  franchiseeLogo: File;
  franchiseeBack: File;
  background: any;

  constructor(
      private _formBuilder: FormBuilder,
      private http: HttpService,
      private dialog: MatDialog
  ) { 
      this.userRole = sessionStorage.getItem('userRole');
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.franchiseeLogo = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.franchisee = reader.result as string;
      };
    }
  }

  onFileBackground(event: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.franchiseeBack = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.background = reader.result as string;
      };
    }
  }

  ngOnInit(): void {
    this.userFormgroup = this._formBuilder.group({
      theme_color: '',
      franchisee_logo: '',
      background: '',
      company_name: '',
      Name: ['', Validators.required],
      Address: '',
      City: ['', Validators.required],
      Contact_No: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 +-]{8,20}')
      ])],
      Email_Id:['', Validators.compose([
        Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')
      ])]
    });

    this.updateForm();
  }
  updateForm(){
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.userData = user.data;
    this.editId = this.userData.Id;
    this.userFormgroup.patchValue({
      company_name: this.userData.company_name, 
      Name: this.userData.Name,
      Address: this.userData.Address,
      City: this.userData.City,
      Contact_No: this.userData.Contact_No,
      Email_Id: this.userData.Email_Id
    });
  }
  saveRecord(){
    let records = this.userFormgroup.value;
    let action = 'Created';
    records.User_Role = sessionStorage.getItem('userRole');
    if(this.editId!==0){
      records.Id = this.editId;
      action = 'Updated';
    }
    let data = JSON.stringify(records);
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('user',data);
    this.http.postDataByForm('users/saveRecord',formdata)
            .then(data => {
              this.inProgress = false;
              this.response = data;
              console.log(this.response);
              if(this.response.status==1){
                sessionStorage.setItem('user',JSON.stringify(this.response));
                sessionStorage.setItem('userName',this.response.data.Name);
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' success!', message: this.response.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.updateForm();
                });
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' fail!', message: this.response.message,popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  console.log(result);
                });
              }
            });
  }

}
