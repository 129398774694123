import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-copycard',
  templateUrl: './copycard.component.html',
  styleUrls: ['./copycard.component.css']
})
export class CopycardComponent implements OnInit {
  title: any = "";
  message: any = "";
  card: any;
  type: any = "popup";
  covertedData: any;
  firstFormGroup: FormGroup;
  isCopied: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any, 
    public dialogRef: MatDialogRef<CopycardComponent>,
    private http: HttpService,
    private _formBuilder: FormBuilder,
    ) { 
      console.log(data);
      this.title = data.title;
      this.message = data.message;
      this.type = data.popup;
      this.card = JSON.parse(data.card);
      console.log(this.card.Company_Name);
  }
  
  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      Company_Name: ['', Validators.required],
      Company_Url: ['', Validators.required],
      Contact_no: ['', Validators.required]
    });

    this.firstFormGroup.patchValue({
      Company_Name: this.card.Company_Name,
      Company_Url: this.card.Company_Url,
      Contact_no: this.card.Contact_no
    });

    this.getUniqueSlug();
  }
  getUniqueSlug(){
    console.log(this.firstFormGroup.get('Company_Name').value);
    let formdata = new FormData();
    formdata.append('company_name',this.firstFormGroup.get('Company_Name').value);
    // formdata.append('Id',this.card.Id);
    this.http.postDataByForm('companies/createslug',formdata)
             .then(data => {
                console.log(data);
                this.covertedData = data;
                this.firstFormGroup.get('Company_Url').setValue(this.covertedData.page_url);
             });
  }
  copyCompany(){
    let formdata = new FormData();
    let data = this.firstFormGroup.value;
    data.Created_by = sessionStorage.getItem('userId');
    formdata.append('company',JSON.stringify(data));
    formdata.append('Id',this.card.Id);
    formdata.append('Francisee_Id',sessionStorage.getItem('franciseeId'));
    formdata.append('company_name',sessionStorage.getItem('company_name'));
    this.http.postDataByForm('companies/copycard',formdata)
             .then(data => {
               console.log(data);
                this.covertedData = data;
                if(this.covertedData.status==1){
                  this.isCopied = true;
                }
             });
  }
  closeDialog(){
    this.dialogRef.close('Yes');
  }
}
