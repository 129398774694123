import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  sideBarOpen: boolean = false;
  userRole: any = "client";
  constructor(private _router: Router) { 
    this.userRole = sessionStorage.getItem('userRole');
  }

  ngOnInit(): void {
    if(!sessionStorage.getItem('isLogin')){
      this._router.navigate(['/']);
    }
  }

  sideBarToggle(event: any) {
      this.sideBarOpen = !this.sideBarOpen;
  }
}
