import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSummernoteModule } from 'node_modules/ngx-summernote';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';

import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { CreatecardComponent } from 'src/app/modules/createcard/createcard.component';
import { StartcardComponent, BottomSheetOverviewExampleSheet, ColorPlat, BackgroundPlat } from 'src/app/modules/startcard/startcard.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { CardlistingComponent } from 'src/app/modules/cardlisting/cardlisting.component';
import { InquiryComponent } from 'src/app/modules/inquiry/inquiry.component';
import { FeedbackComponent } from 'src/app/modules/feedback/feedback.component';
import { ChangepasswordComponent } from 'src/app/modules/changepassword/changepassword.component';
import { MyprofileComponent } from 'src/app/modules/myprofile/myprofile.component';
import { ThemesComponent } from 'src/app/modules/themes/themes.component';
import { ClientsComponent } from 'src/app/modules/clients/clients.component';
import { FranchiseComponent } from 'src/app/modules/franchise/franchise.component';
import { EmployeeComponent } from 'src/app/modules/employee/employee.component';
import { PayhistoryComponent } from 'src/app/modules/payhistory/payhistory.component';
import { AdminComponent } from 'src/app/modules/admin/admin.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RecyclerComponent } from 'src/app/modules/recycler/recycler.component';
import { AffiliateComponent } from 'src/app/modules/affiliate/affiliate.component';

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    CreatecardComponent,
    StartcardComponent,
    BottomSheetOverviewExampleSheet,
    ColorPlat,
    BackgroundPlat,
    CardlistingComponent,
    InquiryComponent, 
    FeedbackComponent, 
    ChangepasswordComponent, 
    MyprofileComponent,
    ThemesComponent, 
    ClientsComponent, 
    FranchiseComponent, 
    EmployeeComponent, 
    PayhistoryComponent, 
    AdminComponent, 
    RecyclerComponent, 
    AffiliateComponent
  ],
  imports: [
    TableModule,
    ButtonModule,
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatCardModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatDividerModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    NgxSummernoteModule,
    NgSelectModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule
  ]
})
export class DefaultModule { }
