<!-- <ng-select [items]="dropdownList"
            bindLabel="item_text"
            placeholder="Select item"
            appendTo="body"
            multiple="true"
            [(ngModel)]="selected">
</ng-select> -->
<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]='!inProgress'>
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>
<div fxLayoutAlign="center center" fxLayout="row" fxFlexFill *ngIf="tab==1">
    <mat-card fxFlex="30" fxFlex.sm="50" fxFlex.xs="90" class="mat-card-background">
        <form [formGroup]="firstFormGroup">
            <h2 style="text-align: center;">Company Name</h2>
            <div class="form-group" [hidden]="!isMNCAllow"> 
                <div class="row">
                    <div class="col-md-2 col-sm-2 col-2" style="padding-right: 0px;">
                        <img [src]="flagUrl+selFlag" style="max-height: 48px; width: 70%; margin-top: -5px;" />
                    </div>
                    <div class="col-md-3 col-sm-3 col-3" style="padding-left: 0px;">
                        <input class="form-control" readonly fxFlex="100" type="text" placeholder="Country code" formControlName="Country_code">
                    </div>
                    <div class="col-md-7 col-sm-7 col-7" style="padding-left: 0px;">
                        <div class="form-group">
                            <mat-select *ngIf="countries.length>0" (selectionChange)="selCountryCode()" class="form-control" formControlName="Countries">
                                <mat-option value="{{country.countries_iso_code}}" *ngFor="let country of countries">{{country.countries_name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <input class="form-control" [readonly]="userRole=='Client'" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="Contact No." formControlName="Mobile_No" required>
            </div>
            <div class="form-group">                
                <input class="form-control"  placeholder="Company Name" (change)="convertStr2Url()" formControlName="Company_Name" required>
            </div>
            <div class="form-group">                
                <input class="form-control" readonly placeholder="Page Unique Url" formControlName="Company_Url" required>
            </div>
            <div style="text-align: center;"  *ngIf="userRole=='Client'">
                <button mat-stroked-button style="float: left;" color="primary" (click)="backToCardlist()" class="bgWhite">
                    <mat-icon>keyboard_backspace</mat-icon> BACK TO CARD
                </button> 
                <button mat-raised-button style="float: right;" color="primary" [disabled]="!firstFormGroup.valid" (click)="createCompany()">Start Card</button>
            </div>
            <div style="text-align: center;"  *ngIf="userRole!=='Client'">
                <button mat-raised-button color="primary" [disabled]="!firstFormGroup.valid" (click)="createCompany()">Start Card</button>
            </div>
        </form>
    </mat-card>    
</div>
<!-- Step 2 createCompany() -->
<div *ngIf="tab==2">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="100" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="100" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Theme Selection</h3>
                </div>
            </div>
        </mat-card>
        </div>
        <div style="margin-top: 15px;" fxLayoutAlign="center center" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
            <!-- <form [formGroup]="secondFormGroup"> -->
            <div *ngIf="themes.length>0" style="padding-left: 15px;">
                <div *ngFor="let theme of themes, let i=index" class="stats-card row">
                    <div style="margin-bottom: 15px;margin-top: 15px;" class="col-md-6">
                        <mat-card (click)="selectTheme(i)" [ngClass]="{'selectedTheme' : theme.Id==selectedThemeId}">
                        <button *ngIf="theme.Id==selectedThemeId" mat-raised-button color="primary"><mat-icon>done_all</mat-icon> SELECTED</button>
                        <img mat-card-image [src]="imagePath+theme.Theme_image" alt="E-Digital Card">
                        <!-- <mat-card-header>
                            <mat-card-title>{{theme.Theme_name}}</mat-card-title>
                        </mat-card-header> -->
                        </mat-card>
                    </div>
                    <div class="col-md-6" *ngIf="theme.Id==selectedThemeId">
                        <div style="margin-bottom: 15px;margin-top: 15px;">
                            <button mat-raised-button [ngStyle]="{'background-color': themeSelectedColor, 'color': '#ffffff'}" (click)="openColorPlat(theme.Available_colors)"><mat-icon>brush</mat-icon> Colors</button><br><br>
                            <span *ngIf="theme.theme_bg.length>0">
                                <img src="{{themeUrl+themeSelectedImg}}" style="max-height: 100px;"><br>
                                <button mat-raised-button [ngStyle]="{'background': themeSelectedImg}" (click)="openBackImagePlat(theme.theme_bg)"><mat-icon>image</mat-icon> Background Image</button><br><br></span>
                            <button mat-stroked-button color="primary" class="bgWhite" (click)="openPreviewDialog()"><mat-icon>remove_red_eye</mat-icon> Preview</button>
                        </div>
                    </div>
                </div>
            </div>  
            <!-- </form> -->
        </mat-card>
    </div>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepTwo()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 3 -->
<div *ngIf="tab==3">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
    <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
        <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
            <div fxFlex="30" color="primary">
                <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                    <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                </button> 
            </div>
            <div fxFlex="40" color="primary" style="text-align: center;">
                <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Basic Information</h3>
            </div>
            <div fxFlex="30" color="primary" style="text-align: right;">
                <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                    <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                </button> 
            </div>
        </div>
    </mat-card>
    </div>
    <form [formGroup]="thirdFormGroup">
    <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
            <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="form-group">
                    <label class="upload-label" for="file"><mat-icon>add_photo_alternate</mat-icon><div>Upload Logo/Profile Pic</div></label>
                    <input id="file" type="file" class="form-control" (change)="onFileChange($event)">
                </div>
                <img [src]="imageSrc" *ngIf="imageSrc" style="max-height: 100px; max-width: 100%;">
            </mat-card>
        </div>
        <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
            <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div formArrayName="userProfile">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="titles">Name Details</div>
                        </div>
                        <div class="col-md-6 mt-2" style="text-align: right;">                                        
                            <button type="button" class="bgWhite" mat-stroked-button type="button" color="primary" (click)="addProfileUser()">
                                <mat-icon>add_box</mat-icon> Add another name
                            </button> 
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="height-divider"></div>
                    <div class="row form-group" style="border-bottom: 1px solid #cccccc;" *ngFor="let profileUser of profileUser().controls; let i=index" [formGroupName]="i">
                        <input type="hidden" formControlName="Id" />
                        <div class="col-md-2">
                            <div class="form-group">
                                <label id="firstName">Prefix</label>
                                <mat-select class="form-control" name="Prefix" formControlName='Prefix'>
                                    <mat-option *ngFor="let prefix of prefixs" [value]="prefix.value">
                                        {{prefix.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>First Name</label>
                                <input class="form-control" placeholder="First Name" formControlName="First_Name" required>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input class="form-control" placeholder="Last Name" formControlName="Last_Name" required>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Designation</label>
                                <input class="form-control" placeholder="Designation" formControlName="Designation" required>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Other Details</label>
                                <input class="form-control"  placeholder="otherDetails" formControlName="Other_details" required>
                            </div>
                        </div>
                        <div class="col-md-2" *ngIf="i>0">
                            <label style="display: block;">&nbsp;</label>
                            <button (click)="removeProfileUser(i)" type="button" class="btn btn-danger">Remove</button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
            <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="titles">Other Details</div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Phone No. (required)</label>
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <span class="country-span">+{{countrCode}}</span>
                                </div>
                                <div class="col-9 pl-0">
                                    <input class="form-control border-radius-right" oninput="this.value=this.value.replace(/[^0-9]/g,'');" type="tel" placeholder="Phone No" formControlName="Contact_no" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Alternative No.</label>
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <span class="country-span">+{{countrCode}}</span>
                                </div>
                                <div class="col-9 pl-0">
                                    <input class="form-control border-radius-right" oninput="this.value=this.value.replace(/[^0-9]/g,'');"  placeholder="Alternative No." formControlName="Alternet_Contact">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Whatsapp No (required)</label>
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <span class="country-span">+{{countrCode}}</span>
                                </div>
                                <div class="col-9 pl-0">
                                    <input class="form-control border-radius-right" oninput="this.value=this.value.replace(/[^0-9]/g,'');"  placeholder="Whatsapp No (required)" formControlName="Whatsapp_No" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Address</label>
                            <textarea class="form-control"  placeholder="Address" formControlName="Company_Address" required></textarea>
                        </div>
                    </div>
                </div>
            </mat-card> 
        </div>       
    </form>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepThree()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 4 -->
<div *ngIf="tab==4">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Links</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <form [formGroup]="fouthFormGroup">
        <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
            <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="Namecards">
                    <div class="form-group">
                        <label>Email Id (optional)</label>
                        <input type="email" class="form-control"  placeholder="Enter Email Id (optional)" formControlName="Email_Id" required>
                    </div>
                    <div class="form-group">
                        <label>Website link (optional)</label>
                        <input class="form-control" type="url" placeholder="Enter Website link (optional)" formControlName="Website">
                    </div>
                    <div class="form-group">
                        <label>Map Location link (optional)</label>
                        <input class="form-control" type="url" placeholder="Enter Map Location link (optional)" formControlName="LocationMap">
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepFour()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 5 -->
<div *ngIf="tab==5">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Social Links (optional)</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="fifthFormGroup">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="Namecards">
                    <div class="form-group">
                        <label>Facebook</label>
                        <input class="form-control"  type="url" placeholder="Enter Facebook Link" formControlName="Facebook">
                    </div>
                    <div class="form-group">
                        <label>Instagram</label>
                        <input class="form-control"  type="url" placeholder="Enter Instagram Link" formControlName="Instagram">
                    </div>
                    <div class="form-group">
                        <label>Twitter</label>
                        <input class="form-control"  type="url" placeholder="Enter Twitter Link" formControlName="Twitter">
                    </div>
                    <div class="form-group">
                        <label>Linkedin</label>
                        <input class="form-control"  type="url" placeholder="Enter Linkedin Link" formControlName="Linkedin">
                    </div>
                    <div class="form-group">
                        <label>Youtube Channel</label>
                        <input class="form-control"  type="url" placeholder="Enter Youtube Channel Link" formControlName="Youtube">
                    </div>
                    <div class="form-group">
                        <label>Pintrest</label>
                        <input class="form-control"  type="url" placeholder="Enter Pintrest Link" formControlName="Pintrest">
                    </div>
                </div>
        </mat-card></div>
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="Namecards">   
                    <div formArrayName="otherlinks"> 
                        <div class="row">
                            <div class="col-md-6">
                                <div class="titles">Other Link</div>
                            </div>
                            <div class="col-md-6 mt-2" style="text-align: right;">                                        
                                <button type="button" mat-stroked-button class="bgWhite" type="button" color="primary" (click)="addMoreLinks()">
                                    <mat-icon>add_box</mat-icon> Add More
                                </button> 
                            </div>
                        </div>
                        <div class="form-group" *ngFor="let otherlink of otherLinks().controls; let i=index" [formGroupName]="i">
                            <div class="row">
                                <input type="hidden" formControlName="Id" />
                                <div class="col-md-8">
                                    <label>Other Links</label>
                                    <input type="url" placeholder="Other Links" formControlName="otherlink" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <label style="display: block;">&nbsp;</label>
                                    <button (click)="removeLink(i)" type="button" class="btn btn-danger">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepFive()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 6 -->
<div *ngIf="tab==6">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">About Us</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="sixFormGroup">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                    <div class="form-group">
                        <label>Establishment Year</label>
                        <input class="form-control"  placeholder="Establishment Year" formControlName="Establishment_Year" required>
                    </div>
                    </mat-card>
                </div>
                    <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                        <mat-card class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                    <div class="form-group">
                        <label>Nature of business</label>
                        <button mat-stroked-button color="primary" class="float-right bgWhite" (click)="popupNature()"><mat-icon>add</mat-icon> Add More</button>
                        <mat-form-field *ngIf="defaultBusiness.length>0 || manualBusiness.length>0">
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let business of defaultBusiness; let i=index"
                                    [removable]="removable" (removed)="removeBusiness('default',i)">{{business}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
                                <mat-chip *ngFor="let business of manualBusiness; let i=index"
                                    [removable]="removable" (removed)="removeBusiness('manual',i)">{{business.businessName}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
                            </mat-chip-list>
                            <input [matChipInputFor]="chipList">
                        </mat-form-field>
                        <div *ngIf="!(defaultBusiness.length>0 || manualBusiness.length>0)">
                            Select atleast one nature of business
                        </div>
                        <input class="form-control" formControlName="Nature_of_business" [hidden]='true'>
                        <input class="form-control" formControlName="Manual_Business" [hidden]='true'>
                    </div>
                </mat-card>
            </div>
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                    <div class="form-group">
                        <label>Specialities</label>
                        <button mat-stroked-button color="primary" class="float-right bgWhite" (click)="popupSpeciality()"><mat-icon>add</mat-icon> Add More</button>
                        <mat-form-field *ngIf="defaultSpeciality.length>0 || manualSpeciality.length>0">
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let speciality of defaultSpeciality; let i=index"
                                    [removable]="removable" (removed)="removeSpeciality('default',i)">{{speciality}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
                                <mat-chip *ngFor="let speciality of manualSpeciality; let i=index"
                                    [removable]="removable" (removed)="removeSpeciality('manual',i)">{{speciality.businessName}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
                            </mat-chip-list>
                            <input [matChipInputFor]="chipList">
                        </mat-form-field>
                        <div *ngIf="!(defaultSpeciality.length>0 || manualSpeciality.length>0)">
                            Select atleast one speciality
                        </div>
                        <input class="form-control" formControlName="Specialties" [hidden]='true'>
                        <input class="form-control" formControlName="Manual_Specialties" [hidden]='true'>
                    </div>
                </mat-card>
            </div>
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                    <div class="form-group">
                        <label>About Us</label>
                        <textarea class="form-control summernote" [ngxSummernote]="config" placeholder="About Us" formControlName="About_Us" required><p>Hello, world</p><p>Summernote can insert HTML string</p></textarea>
                    </div>
            </mat-card>
        </div>
            <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div>
                    Upload Company Documents/Brouchers (Optional) - Max 2 pdfs of 5 MB each
                    <label for="pdfFile" class="upload-label"><mat-icon>picture_as_pdf</mat-icon><div>Select PDF Brochure</div></label>
                    <input type="file" id="pdfFile" class="form-control" (change)="selectPdf($event)" multiple>
                    <mat-divider></mat-divider>
                    <div *ngIf="pdfBrochureArr.length>0">
                        <div class="row" *ngFor="let pdf of pdfBrochureArr; let i=index">
                            <div class="col-md-10" style="margin-left:10px; margin-top: 15px; position: relative;">
                                <iframe [src]="domreturn(pdfSrc+pdf)" style="height: 250px; width: 100%;"></iframe>
                                <button (click)="removePDF(i)" type="button" mat-icon-button class="btn btn-danger" style="position: absolute; right: -25px; top: 0px;"><mat-icon>delete_sweep</mat-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepSix()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 7 -->
<div *ngIf="tab==7">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Products/Services</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="sevenFormGroup">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <div fxFlex="45" class="mat-card" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                    <div [hidden]="!isStore">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-3">
                                    <label style="font-weight: 500;
                                    margin-bottom: 0px;
                                    color: #050a30b8;">View as </label>
                                </div>
                                <div class="col-md-9 row">
                                    <div class="col-md-6">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" class="custom-control-input" (change)="viewAsChange('product')" checked id="sproduct" value="product" formControlName="viewAs">
                                            <label class="custom-control-label" for="sproduct">Product</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" class="custom-control-input" (change)="viewAsChange('store')" id="store" value="store" formControlName="viewAs">
                                            <label class="custom-control-label" for="store">Store</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" [hidden]="viewAs=='product'">
                            <div class="row">
                                <div class="col-md-3">
                                    <label style="font-weight: 500;
                                    margin-bottom: 0px;
                                    color: #050a30b8;">Design Layout</label>
                                </div>
                                <div class="col-md-9 row">
                                    <div class="col-md-6">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" class="custom-control-input" checked id="oneColumn" value="1" formControlName="layout">
                                            <label class="custom-control-label" for="oneColumn">One Column per Row</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" class="custom-control-input" id="twoColumn" value="2" formControlName="layout">
                                            <label class="custom-control-label" for="twoColumn">Two Column per Row</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formArrayName="productlists"> 
                        <div>
                            <mat-card class="form-group mat-card-background" style="position: relative; padding: 15px;  margin-top: 5px; border: 1px solid #ffffff;" *ngFor="let productList of productLists().controls; let i=index" [formGroupName]="i">
                                <div>
                                    <button (click)="removeProduct(i)" type="button" mat-icon-button class="btn btn-danger" style="position: absolute; right: -15px; top: -15px;"><mat-icon>delete_sweep</mat-icon></button>
                                    <input type="hidden" formControlName="Id" />
                                    <div class="Namecards">
                                        <div class="form-group">
                                            <label>Product/Service Name</label>
                                            <input class="form-control"  placeholder="Product/Service Name" formControlName="Product_Name" required>
                                        </div>
                                        <div class="form-group">
                                            <label>Product Description</label>
                                            <textarea class="form-control" [ngxSummernote]="config" placeholder="Product/Service Description" formControlName="Details" required></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label>Product/Service Price (Optional)</label>
                                            <input class="form-control"  placeholder="Product/Service Price (Optional)" formControlName="Product_Price" required>
                                        </div>
                                        <span [hidden]="viewAs=='product'">
                                            <div class="form-group">
                                                <label>Discountable Product/Service Price (Optional)[Leave blank if discount not Available]</label>
                                                <input class="form-control" placeholder="Discountable Product/Service Price (Optional)" formControlName="Product_Discount" required>
                                            </div>
                                            <div class="form-group">
                                                <label>Available Qty Product (0 - Out of stock / Not Available)</label>
                                                <input class="form-control"  placeholder="Available Qty" formControlName="Product_Qty" required>
                                            </div>
                                        </span>
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="switch{{i}}" value="1" formControlName="Hide_enquiry">
                                                <label class="custom-control-label" for="switch{{i}}">Hide Enquiry Button</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="upload-label" for="proImage{{i}}"><mat-icon>add_a_photo</mat-icon><div>Product/Service Image</div></label>
                                            <input type="file" class="form-control" id="proImage{{i}}" (change)="onProductImgChange($event,i)">
                                            <input class="form-control" [hidden]='true' placeholder="Product Image" formControlName="productImage" required>
                                        </div>
                                        <div *ngIf="imageProductSrc[i]">
                                            <div class="row" *ngIf="imageProductSrc[i]['result']!=''">
                                                <div class="col-md-8">
                                                    <img [src]="imageProductSrc[i]['result']" *ngIf="imageProductSrc[i]['productId']==i" class="w-100">
                                                </div>
                                                <div class="col-md-4">
                                                    <button type="button" mat-mini-fab type="button" color="primary" (click)="removProduct(i)">
                                                        <mat-icon>close</mat-icon>
                                                    </button> 
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>Product Youtube Video</label>
                                            <input class="form-control"  placeholder="Product Youtube Video Link (Optional)" formControlName="product_video">
                                        </div>
                                        <div class="form-group">
                                            <label>Product Brochure (Optional)</label>
                                            <input type="file" class="form-control" (change)="onProductPdfChange($event,i)">
                                        </div>
                                        <div *ngIf="ProductPdf[i]">
                                            <iframe [src]="domreturn(pdfSrc+ProductPdf[i].productPdf)" style="height: 300px;"></iframe>
                                            <button (click)="removeProductPDF(i)" type="button" mat-icon-button class="btn btn-danger" style="position: absolute; right: 10px; bottom: -5px;"><mat-icon>delete_sweep</mat-icon></button>
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="switchShare{{i}}" value="1" formControlName="is_Sharable">
                                                <label class="custom-control-label" for="switchShare{{i}}">is Share</label>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-2" style="text-align: right;">                                        
                                <button type="button" mat-fab type="button" color="primary" (click)="addMoreProducts()">
                                    <mat-icon>add</mat-icon>
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
            <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                    <div fxFlex.xs="35" fxFlex="35" color="primary">
                        <button mat-stroked-button color="primary" (click)="goBackStep()">
                            <mat-icon>keyboard_backspace</mat-icon> Back
                        </button> 
                    </div>
                    <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                        <strong>{{tab}}</strong> / {{totalTabs}}
                    </div>
                    <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepSeven()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 8 -->
<div *ngIf="tab==8">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Payment Options (Optional)</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="eightFormGroup"> 
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                <div formArrayName="upilists">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="titles">UPI payment Options</div>
                        </div> 
                        <div class="col-md-4" style="text-align: right;">
                            <button type="button" mat-stroked-button class="bgWhite" type="button" color="primary" (click)="addNewUPI()">
                                <mat-icon>add_box</mat-icon> Add More
                            </button>
                        </div>
                    </div>                    
                    <div *ngFor="let upilst of otherUPIs().controls; let i=index" [formGroupName]="i">  
                        <div class="row">
                            <input type="hidden" formControlName="Id" />
                            <div class="col-md-5 form-group">
                                <label>Payment Name</label>
                                <input type="text" placeholder="Payment Name" formControlName="Payment_Name" class="form-control">
                            </div>
                            <div class="col-md-5 form-group">
                                <label>UPI Contact No</label>
                                <input type="tel" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="UPI Contact No" formControlName="Payment_contactNo" class="form-control">
                            </div>
                            <div class="col-md-2 form-group text-center">
                                <button (click)="removeUPI(i)" mat-icon-button type="button" class="btn btn-danger"><mat-icon>delete_sweep</mat-icon></button>
                            </div>
                            <div class="form-group col-md-5">
                                <label class="upload-label" for="upcImage{{i}}"><mat-icon>qr_code_scanner</mat-icon><div>UPI QC code</div></label>
                                <input type="file" class="form-control" id="upcImage{{i}}" (change)="onQCImgChange($event,i)">
                            </div>
                            <div class="form-group col-md-4" style="text-align: center;">
                                <div *ngIf="upiQCImg[i]">
                                    <div class="row" *ngIf="upiQCImg[i]['result']!=''">
                                        <div class="col-md-8">
                                            <img [src]="upiQCImg[i]['result']" *ngIf="upiQCImg[i]['upiId']==i" class="w-100">
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" mat-mini-fab type="button" color="primary" (click)="removUPI(i)">
                                                <mat-icon>close</mat-icon>
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                    </div>
                </div>
            </mat-card>
        </div>
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">
                <div class="titles">Bank Account Details</div>
                <div class="Namecards">
                    <div class="form-group">
                        <label>Bank Name</label>
                        <input class="form-control"  placeholder="Bank Name" formControlName="Bank_Name">
                    </div>
                    <div class="form-group">
                        <label>Account Holder Name</label>
                        <input class="form-control"  placeholder="Account Holder Name" formControlName="Account_Holder">
                    </div>
                    <div class="form-group">
                        <label>Account Number</label>
                        <input class="form-control"  placeholder="Account Number" formControlName="Account_No">
                    </div>
                    <div class="form-group">
                        <label>Account Type</label>
                        <input class="form-control"  placeholder="Account Type" formControlName="Account_Type">
                    </div>                    
                    <div class="form-group">
                        <label>IFSC Code</label>
                        <input class="form-control"  placeholder="IFSC Code" formControlName="IFSC_Code">
                    </div>
                    <div class="form-group">
                        <label>PAN Card No.</label>
                        <input class="form-control"  placeholder="PAN Card No." formControlName="PAN_no">
                    </div>
                    <div class="form-group">
                        <label>GSTin No.</label>
                        <input class="form-control"  placeholder="GSTin No." formControlName="GST_no">
                    </div>
                </div>
            </mat-card>
        </div>
        </form>
        <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
            <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                    <div fxFlex.xs="35" fxFlex="35" color="primary">
                        <button mat-stroked-button color="primary" (click)="goBackStep()">
                            <mat-icon>keyboard_backspace</mat-icon> Back
                        </button> 
                    </div>
                    <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                        <strong>{{tab}}</strong> / {{totalTabs}}
                    </div>
                    <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepEight()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 9 -->
<div *ngIf="tab==9">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Photo Gallery</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
        <form [formGroup]="nineFormGroup">
            <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label class="upload-label" for="galleryImages"> <mat-icon>photo_library</mat-icon> <div>Select Photos Gallery</div></label>
                            <input multiple type="file" id="galleryImages" class="form-control" (change)="onImgGalleryChange($event)">
                        </div>
                        <input type="hidden" formControlName="imggallery" />
                    </div>
                    <div class="row" *ngIf="galleryImage">
                        <div class="form-group col-md-4"  *ngFor="let gallery of editGalleryImage; let i=index" style="text-align: center;">
                            <img [src]="this.galleryPath+gallery" *ngIf="gallery" style="width: 100%;">
                            <div class="text-center">
                                <button (click)="removeEditGallery(i)" mat-icon-button type="button" class="btn btn-danger"><mat-icon>delete_sweep</mat-icon></button>
                            </div>
                        </div>
                        <div class="form-group col-md-4"  *ngFor="let gallery of galleryImage; let i=index" style="text-align: center;">
                            <img [src]="gallery" *ngIf="gallery" style="width: 100%;">
                            <div class="text-center">
                                <button (click)="removeGallery(i)" mat-icon-button type="button" class="btn btn-danger"><mat-icon>delete_sweep</mat-icon></button>
                            </div>
                        </div>
                        <!-- <div class="form-group col-md-3">
                            <label>Gallery View Type</label>
                            <mat-select class="form-control" formControlName='galleryType'>
                                <mat-option *ngFor="let galleryType of galleryTypes" [value]="galleryType">
                                    {{galleryType}}
                                </mat-option>
                            </mat-select>
                        </div> -->
                    </div>
                </mat-card>
            </div>
        </form>
        <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
            <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                    <div fxFlex.xs="35" fxFlex="35" color="primary">
                        <button mat-stroked-button color="primary" (click)="goBackStep()">
                            <mat-icon>keyboard_backspace</mat-icon> Back
                        </button> 
                    </div>
                    <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                        <strong>{{tab}}</strong> / {{totalTabs}}
                    </div>
                    <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepNine()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 10 -->
<div *ngIf="tab==10">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Youtube Videos (Optional)</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="tenFormGroup">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                <div formArrayName="youtubes">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button type="button" mat-stroked-button class="bgWhite" type="button" color="primary" (click)="addNewVideo()">
                                <mat-icon>add_box</mat-icon> Add New Video
                            </button>
                        </div>
                    </div>
                    <div class="row" style="margin: 10px 0px;" *ngFor="let youtube of youtubeVideos().controls; let i=index" [formGroupName]="i">
                        <input type="hidden" formControlName="Id" />
                        <div class="col-md-9">
                            <input type="url" placeholder="Enter Youtube Video Link" formControlName="videourl" class="form-control">
                        </div>
                        <div class="col-md-3">
                            <button (click)="removeVideo(i)" mat-icon-button type="button" class="btn btn-danger"><mat-icon>delete_sweep</mat-icon></button>
                        </div>                        
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex.xs="35" fxFlex="35" color="primary">
                    <button mat-stroked-button color="primary" (click)="goBackStep()">
                        <mat-icon>keyboard_backspace</mat-icon> Back
                    </button> 
                </div>
                <div fxFlex.xs="12" fxFlex="33" color="primary" style="text-align: center;">
                    <strong>{{tab}}</strong> / {{totalTabs}}
                </div>
                <div fxFlex.xs="53" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveStepTen()">
                        <!-- <mat-icon>save</mat-icon> --> Save & Continue <mat-icon>forward</mat-icon>
                    </button> 
                </div>
            </div>
        </mat-card>
    </div>
    <br><br>
</div>
<!-- Step 11 -->
<div *ngIf="tab==11">
    <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
        <mat-card fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" >
            <div fxLayout="row" style="margin-bottom: 15px;" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                <div fxFlex="30" color="primary">
                    <button mat-stroked-button color="primary" (click)="backToCardlist()" class="bgWhite">
                        <mat-icon>keyboard_backspace</mat-icon> <span class="hidden-sm-down">BACK TO CARDS</span>
                    </button> 
                </div>
                <div fxFlex="40" color="primary" style="text-align: center;">
                    <h3 style="text-align: center; margin-bottom: 0px;" class="dashboardstat">Settings</h3>
                </div>
                <div fxFlex="30" color="primary" style="text-align: right;">
                    <button mat-raised-button color="primary" (click)="openPreviewDialog()">
                        <mat-icon>visibility</mat-icon> <span class="hidden-sm-down">VIEW PREVIEW</span>
                    </button> 
                </div>
            </div>
        </mat-card>
        </div>
            <form [formGroup]="elevenFormGroup">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                <div class="Namecards">
                    <div class="titles">Visibility settings</div>
                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" checked class="custom-control-input" id="switch1" value="1" formControlName="is_Aboutus">
                            <label class="custom-control-label" for="switch1">Hide About Us Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch2" value="1" formControlName="is_Products">
                            <label class="custom-control-label" for="switch2">Hide Products/Services Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch3" value="1" formControlName="is_Payment">
                            <label class="custom-control-label" for="switch3">Hide Payment Options Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch4" value="1" formControlName="is_Gallery">
                            <label class="custom-control-label" for="switch4">Hide Gallery Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch5" value="1" formControlName="is_Youtube">
                            <label class="custom-control-label" for="switch5">Hide Youtube Videos Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch6" value="1" formControlName="is_Feedback">
                            <label class="custom-control-label" for="switch6">Hide Feedback Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch7" value="1" formControlName="is_Enquiry">
                            <label class="custom-control-label" for="switch7">Hide Enquiry Form Section</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch8" value="1" formControlName="is_Unentered_Social">
                            <label class="custom-control-label" for="switch8">Hide Unentered Social Media Links</label>
                        </div>
                    </div>
                </div>
                    </mat-card>
                </div>
            </form>
            <form [formGroup]="customise_headers">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                <div>
                   <div class="titles">Customise headers</div>
                   <div class="row">
                       <div class="col-md-12">
                           <div class="form-group">
                                <label>About Us Section</label>
                                <input class="form-control"  placeholder="About Us Section" formControlName="About_Us_Section">
                           </div>
                       </div>
                       <div class="col-md-12">
                        <div class="form-group">
                             <label>Product/Service Section</label>
                             <input class="form-control"  placeholder="Product/Service Section" formControlName="Products_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Payment Section</label>
                             <input class="form-control"  placeholder="Payment Section" formControlName="Payment_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Gallery Section</label>
                             <input class="form-control"  placeholder="Gallery Section" formControlName="Gallery_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Youtube Videos Section</label>
                             <input class="form-control"  placeholder="Youtube Videos Section" formControlName="Youtube_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Feedback Section</label>
                             <input class="form-control"  placeholder="Feedback Sectio" formControlName="Feedback_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Enquiry Section</label>
                             <input class="form-control"  placeholder="Enquiry Section" formControlName="Enquiry_Section">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Company Name Subheading (In about us)</label>
                             <input class="form-control"  placeholder="Company Name Subheading (In about us)" formControlName="Company_Subheading">
                        </div>
                    </div>
                   </div>
                </div>
            </mat-card>
        </div>
        </form>
        <form [formGroup]="companyseo">
                <div fxLayoutAlign="center center" fxLayout="row" class="margin-10">
                    <mat-card  class="mat-card-background pad-10" fxFlex="45" fxFlex.sm="90" fxFlex.xs="90" style="padding-right: 5px; padding-left: 5px;">               
                   <div class="titles">SEO Meta Data (Optional)</div>
                   <div class="row">
                       <div class="col-md-12">
                           <div class="form-group">
                                <label>Page Title [Maximum 60 character]</label>
                                <input class="form-control" maxlength="60"  placeholder="Page Title [Maximum 60 character]" formControlName="page_title">
                           </div>
                       </div>
                       <div class="col-md-12">
                        <div class="form-group">
                             <label>Description [Maximum 160 character]</label>
                             <input class="form-control" maxlength="160" placeholder="Description [Maximum 160 character]" formControlName="page_description">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                             <label>Keywords [Add comma(,) separated]</label>
                             <input class="form-control" placeholder="Keywords [Add comma(,) separated]" formControlName="page_keywords">
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
            </form>
            <div fxLayoutAlign="center center" class="startcard-header" fxLayout="row">
                <mat-card fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="pad-10">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card">
                        <div fxFlex.xs="35" fxFlex="35" color="primary">
                            <button mat-stroked-button color="primary" (click)="goBackStep()">
                                <mat-icon>keyboard_backspace</mat-icon> Back
                            </button> 
                        </div>
                        <div fxFlex.xs="33" fxFlex="33" color="primary" style="text-align: center;">
                            <strong>{{tab}}</strong> / {{totalTabs}}
                        </div>
                        <div fxFlex.xs="35" fxFlex="35" color="primary" style="text-align: right;">
                    <button mat-stroked-button color="primary" (click)="saveFinal()">
                        <!-- <mat-icon>save</mat-icon> --> Finish !
                    </button> 
                </div>
            </div>
            </mat-card>
        </div>
    <br><br>
</div>