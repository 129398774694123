import { Component } from '@angular/core';
import { HttpService } from './services/http.service';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { TranslateService } from '@ngx-translate/core';  
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertpopupComponent } from './common/alertpopup/alertpopup.component';

@Component({
  selector: 'my-digicard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'edigitalcard';
  respo: any;
  allRecords: any;
  constructor(
    private http: HttpService,
    public translate: TranslateService,
    private dialog: MatDialog, 
    private _router: Router, 
    private bnIdle: BnNgIdleService
  ) { 
      this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
        if(isTimedOut) {
          if(sessionStorage.getItem('isLogin')){
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: 'Session timeout', message: "30 minutes idle system session expired. Please, login again..", popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => { 
              sessionStorage.removeItem('user');
              sessionStorage.removeItem('userId');
              sessionStorage.removeItem('userName');
              sessionStorage.removeItem('isLogin');
              this._router.navigate(['/']);
            });
          }
        }
      });
      this.getCountry();
      // // this.getDetails();
      // translate.addLangs(['en', 'fr']);  
      // if (localStorage.getItem('locale')) {  
      //   const browserLang = localStorage.getItem('locale');  
      //   translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');  
      // } else {  
      //   localStorage.setItem('locale', 'en');  
      //   translate.setDefaultLang('en');  
      // }  
  }
  // getIPAddress(){
  //   this.http.getDataByUrl('http://api.ipify.org/?format=json')
  //       .then((data:any) => {
  //         console.log(data.ip);
  //         sessionStorage.setItem("IPAddress",data.ip);
  //       });
  // }
  getCountry(){
    let formdata = new FormData();
    formdata.append('pageUrl','http://www.geoplugin.net/json.gp');
    this.http.postDataByForm("common/getcronurl",formdata)
        .then((data:any) => {
          console.log(data);
          sessionStorage.setItem("country",data.geoplugin_countryName);
          sessionStorage.setItem("currency_code",data.geoplugin_currencyCode);
          sessionStorage.setItem("currency_symbol",data.geoplugin_currencySymbol);
        });
  }
  changeLang(language: string) {  
    localStorage.setItem('locale', language);  
    this.translate.use(language);  
  } 
  getDetails(){
    let requestURL = window.location.href;
    let splashRequest = requestURL.split("/");
    requestURL = splashRequest[0]+"//"+splashRequest[2]+"/";
    console.log(requestURL);
    let formdata = new FormData();
    formdata.append('White_label',requestURL);
    this.http.postDataByForm('users/get_franchisee',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            this.allRecords = this.respo.data;
            console.log(this.allRecords[0]);
            document.documentElement.style.setProperty('--web-color', this.allRecords[0].theme_color);
            document.documentElement.style.setProperty('--primary-color', this.allRecords[0].theme_color+' !important');
            document.documentElement.style.setProperty('--web-color-light', this.allRecords[0].theme_color+'29');
          }
        });
  }
}
