import { Component, OnInit } from '@angular/core';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.css']
})
export class FranchiseComponent implements OnInit {

  userFormgroup: FormGroup;
  allRecords: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  newRespo: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  openUser: boolean = false;
  editId: any = 0;
  inProgress: boolean = false;
  response: any;
  isMNCAllow: boolean = false;
  userRoles: any = ["Client","Employee","Franchisee"];
  userRole: any = "Admin";
  scrollingSubscription: any;
  scrollCDK: any;

  exportColumns: any[];
  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    public scroll: ScrollDispatcher,
    private dialog: MatDialog
   ) { 
    if(sessionStorage.getItem('mncCard')=='Allow'){
      this.isMNCAllow = true;
    }
    this.userRole = sessionStorage.getItem('userRole');
    if(this.userRole=="Super-Admin"){
      this.userRoles.push("Admin");
    }

    this.scrollingSubscription = this.scroll
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        // this.onWindowScroll(data);
        this.scrollCDK = data;
      });
   }

  ngOnInit(): void {
    this.userFormgroup = this._formBuilder.group({
      White_label: '',
      Main_Url: '',
      company_name: '',
      razor_Live_Key: '',
      isStore: '',
      Name: ['', Validators.required],
      Address: '',
      City: ['', Validators.required],
      Contact_No: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 +-]{8,20}')
      ])],
      isMNC: '0',
      Password: ['', Validators.required],
      Email_Id:['', Validators.compose([
        Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')
      ])],
      User_Role: 'Franchisee'
    });
    this.initTable();
    this.getDetails();
  }
  initTable(){
    this.cols = [
      { field: 'Id', header: 'Id' },
      { field: 'Name', header: 'Name' },
      { field: 'Contact_No', header: 'Contact No' },
      { field: 'Email_Id', header: 'Email Id' },
      { field: 'Address', header: 'Address' },
      { field: 'City', header: 'City' },
      { field: 'Created_by', header: 'Created By' },
      { field: 'Register_Date', header: 'Register Date' },
    ];
  }
  saveRecord(){
    let records = this.userFormgroup.value;
    let action = 'Created';
    if(this.editId!==0){
      records.Id = this.editId;
      action = 'Updated';
    }
    records.Francisee_Id = sessionStorage.getItem('userId');
    records.Created_by = sessionStorage.getItem('userId');
    records.company_name = records.Name;
    let data = JSON.stringify(records);
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('user',data);
    this.http.postDataByForm('users/saveRecord',formdata)
            .then(data => {
              this.inProgress = false;
              this.response = data;
              console.log(this.response);
              if(this.response.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' success!', message: this.response.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.getDetails();
                    this.userFormgroup.reset();
                    if(this.editId){
                        this.openUser = false;
                        this.editId = 0;
                    }
                });
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' fail!', message: this.response.message,popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  console.log(result);
                });
              }
            });
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  getDetails(){
    let formdata = new FormData();
    formdata.append('Francisee_Id',sessionStorage.getItem('franciseeId'));
    formdata.append('User_Role','Franchisee');
    formdata.append('requestUser',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('users/get_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allRecords = this.respo.data;
            this.totalRecords = this.datasource.length;
          }
        });
  }
  changeStatus(user: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.changeConfirm(user);
        }
    });
  }
  changeConfirm(user: any){
    console.log(user);
    user.isActive = user.isActive=='0'?'1':'0';
    let formdata = new FormData();
    let firstForm = {
      Contact_No: user.Contact_No,
      Id: user.Id,
      // Updated_by: sessionStorage.getItem('userId'),
      isActive: user.isActive
    };
    let data = JSON.stringify(firstForm);
    formdata.append('user',data);
    this.http.postDataByForm('users/saveRecord',formdata)
            .then(data => {
                console.log(data);
            });
  }
  editRecords(user: any){
    console.log(user);
    this.editId = user.Id;
    this.userFormgroup.patchValue({
      Name: user.Name,
      Address: user.Address,
      City: user.City,
      isMNC: user.isMNC,
      Contact_No: user.Contact_No,
      Password: user.Password,
      Email_Id: user.Email_Id
    });
    this.openUser = true;
    // To reach at top of window
    this.scrollCDK.getElementRef().nativeElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  deleteRecords(user: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to delete!', message: "Are you sure to delete, <strong>Once deleted, can not restore it.<strong>", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.deleteRecord(user);
        }
    });
  }
  deleteRecord(user: any){
    console.log(user);
    let formdata = new FormData();
    let firstForm = {
      Contact_No: user.Contact_No,
        Id: user.Id,
        Deleted_by: sessionStorage.getItem('userId'),
        isDelete: '1',
        isActive: '0',
        Deleted_date: new Date('Y-m-d h:i:s')
    };
    let data = JSON.stringify(firstForm);
    formdata.append('user',data);
    this.http.postDataByForm('users/saveRecord',formdata)
            .then(data => {
                console.log(data);
                this.newRespo = data;
                if(this.newRespo.status==1){
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Action success!', message: "Record deleted successfully.",popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.getDetails();
                      this.userFormgroup.reset();
                  });
                }
            });
  }
  createRecord(){
      this.userFormgroup.reset();
      this.openUser = true;
  }
  closeform(){
    this.openUser = false;
  }
}
