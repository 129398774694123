import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css']
})
export class AffiliateComponent implements OnInit {
  userToken: any;
  constructor(
    private route: ActivatedRoute,
    private _route: Router
  ) { 
    this.route.params.subscribe( 
      params => {
        console.log(params);
        this.userToken = params['userToken'];
        sessionStorage.setItem("createdBy",this.userToken);
        console.log("createdBy",this.userToken);
        this._route.navigate(["/register/"]);
      }
    );
  }

  ngOnInit(): void {
    
  }

}
