import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  invalidUser: boolean = true;
  topValidUser: boolean = true;
  otpSent: boolean = false;
  firstFormGroup: FormGroup;
  userFormgroup: FormGroup;
  inProgress: boolean = false;
  userStatus: any = [];
  isRegister: boolean = false;
  response: any;
  selected: any = "91";
  OTPNo: any;
  counter: any = 30;
  timer: any;
  invalidContact: boolean = true;
  countries: any = [];
  flagUrl: any;
  selFlag: any = "in.png";
  respo: any;
  requestURL: any = "e-digicard.com";
  allRecords: any;
  background: any = "../../../assets/images/background.png";
  Franchisee_logo: any;
  company_name: any = "mydigiCard";
  passwordType: any = "password";
  franchiseeId: any = "1";
  package: any;
  packageId: any;

  constructor(
      private _formBuilder: FormBuilder,
      private http: HttpService,
      public dialog: MatDialog,
      private _router: Router,
      private route: ActivatedRoute
    ) { 
      if(sessionStorage.getItem('isLogin')){
        this._router.navigate(['/'+sessionStorage.getItem('userRole').toLowerCase()]);
      }

        this.flagUrl = this.http.serviceUrl+"flags/";
        this.route.params.subscribe( 
          params => {
            if(params['packageName']){
              this.package = params['packageName'];
            }
          }
        );
        this.route.data.subscribe( 
          data => {
            if(data['register']){
              this.getPackages();
            }
            console.log(data);
          }
        );
    }
  changeType() {
      if(this.passwordType=='password'){
        this.passwordType='text';
      }
      else {
        this.passwordType='password';
      }
  }  
  ngOnInit(): void {
    this.requestURL = window.location.href;
    let url = this.requestURL.split("/");
    let newUrl = url[2].split(".");
    if(url[2]!='localhost:4200'){
      this.requestURL = "https://"+newUrl[newUrl.length-2]+"."+newUrl[newUrl.length-1]+"/";
    }
    else {
      this.requestURL = "https://"+url[2]+"/";
    }
    console.log(this.requestURL);
    sessionStorage.setItem('mainURL',this.requestURL);

    // this.getDetails();

    this.firstFormGroup = this._formBuilder.group({
      contact_no: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,20}')
      ])],
      password: ['', Validators.required]
    });
    this.getCountry();
    this.userFormgroup = this._formBuilder.group({
      Name: ['', Validators.required],
      Address: '',
      Country_code: '91',
      selectedCountry: '91',
      Contact_No: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,20}')
      ])],
      // currentOTP: ['', Validators.compose([
      //   Validators.required,
      //   Validators.pattern('^[0-9]{6}')
      // ])],
      Password: ['', Validators.required],
      Email_Id:['', Validators.compose([
        Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')
      ])],
      TermCondition: ['', Validators.required]
    });
    this.userFormgroup.get('selectedCountry').setValue("91");
  }

  getDetails(){
    let formdata = new FormData();
    formdata.append('White_label',this.requestURL);
    this.http.postDataByForm('users/get_franchisee',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            this.allRecords = this.respo.data;
            sessionStorage.setItem("whiteLabel",JSON.stringify(this.allRecords[0]));
            sessionStorage.setItem("cardType",this.allRecords[0].allow);
            this.packageId = this.allRecords[0].Id;
            console.log(this.allRecords[0]);
            this.franchiseeId = this.allRecords[0].Id;
            if(this.allRecords[0].background!=''){
              // this.background = this.http.serviceUrl+"company-profile/"+this.allRecords[0].background;
            }
            if(this.allRecords[0].franchisee_logo!=''){
              // this.Franchisee_logo = this.http.serviceUrl+"company-profile/"+this.allRecords[0].franchisee_logo;
            }
          }
          else {
            this.franchiseeId = "1";
          }
        });
  }

  registerNow(type: any){
    if(type=='register'){
      this.isRegister = true;
    }
    else {
      this.isRegister = false;
      this._router.navigate(['/']);
    }
  }

  contactMadeValid(){
    let contact = this.userFormgroup.get('Contact_No').value;
    this.invalidContact = true;
    if(contact.length>=10){
        this.invalidContact = false;
    }
  }
  getCountry(){
    this.http.getDataByPage('users/get_country')
        .then((data:any) => {
          console.log(data);
          if(data.status==1){
              this.countries = data.data.records;
          }
        });
  }
  getPackages() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('packName',this.package);
    this.http.postDataByForm('subscription/get_packages',formdata)
        .then(data => {
          console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            this.isRegister = true;
            sessionStorage.setItem("package",JSON.stringify(this.respo.data.records[0]));
            console.log(this.respo.data.records[0]);
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: 'Package not exist !', message: "Provided package do not exist. It may depricate or removed. Please, try again.", popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
              this._router.navigate(['/']);
            });
          }
        });
  }
  selCountryCode() {
    let countryCode = this.userFormgroup.get('Country_code').value;
    this.countries.forEach(c => {
        if(c.countries_isd_code==countryCode){
          this.selFlag = (c.countries_iso_code.toString().toLowerCase())+".png";
          this.userFormgroup.get('selectedCountry').setValue(countryCode);
        }
    });
  }
  saveRecord(){
    let records = this.userFormgroup.value;
    records.Francisee_Id = this.franchiseeId;
    records.User_Role = "Client";
    if(this.packageId){
        records.userPackId = this.packageId;
    }
    let data = JSON.stringify(records);
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('user',data);
    this.http.postDataByForm('users/saveRecord',formdata)
            .then(data => {
              this.inProgress = false;
              this.response = data;
              this.userStatus  = data;
              console.log(this.response);
              if(this.response.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Register success!', message: this.response.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.userFormgroup.reset();
                    this.isRegister = false;
                    sessionStorage.setItem('user',JSON.stringify(data));
                    sessionStorage.setItem('company_name',this.userStatus.data.company_name);
                    sessionStorage.setItem('userId',this.userStatus.data.Id);
                    sessionStorage.setItem('userCards',this.userStatus.data.userCards);
                    sessionStorage.setItem('userIcon',this.userStatus.data.Avatar);
                    let franciseeId = this.userStatus.data.Francisee_Id;
                    if(this.userStatus.data.User_Role=='Super-Admin' || this.userStatus.data.User_Role=='Admin' || this.userStatus.data.User_Role=='Franchisee'){
                        franciseeId = this.userStatus.data.Id;
                    }
                    console.log("I am Franchisee : "+franciseeId);
                    sessionStorage.setItem('mncCard',this.userStatus.data.isMNC);
                    sessionStorage.setItem('isStore',this.userStatus.data.isStore);
                    sessionStorage.setItem('franciseeId',franciseeId);
                    sessionStorage.setItem('userName',this.userStatus.data.Name);
                    sessionStorage.setItem('userRole',this.userStatus.data.User_Role);
                    sessionStorage.setItem("userPackId",this.packageId);
                    sessionStorage.setItem('isLogin','Yes');
                    this._router.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+"/createcard");
                });
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Register fail!', message: this.response.message,popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  console.log(result);
                });
              }
            });
  }
  checkValidContact(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('contact',this.userFormgroup.get('Contact_No').value);
    this.http.postDataByForm('users/send_otp',formdata)
           .then(data => {
              console.log(data);
              this.userStatus  = data;
              this.inProgress = false;
              if(this.userStatus.status==1){
                this.OTPNo = this.userStatus.data.OTPNo;
                this.otpSent = true;
                this.counter = 30;
                clearInterval(this.timer);
                this.startTimer();
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Action fail!', message: this.response.message,popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                });
              }
           });
  }
  startTimer(){
      this.timer = setInterval(() => {
        this.counter = parseInt(this.counter) - 1; //set time variable with current date 
        if(this.counter<=0){
            clearInterval(this.timer);
        }
       }, 1000);
  }
  checkValidOTP(){
      if(parseInt(this.userFormgroup.get('currentOTP').value.length)==6){
          if(this.userFormgroup.get('currentOTP').value==this.OTPNo){
              this.topValidUser = false;
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: 'OTP Invalid !', message: "Entered OTP is invalid with given Contact No.", popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
              this.userFormgroup.get('currentOTP').setValue("");
            });
          }
      }
  }
  checkValidUser(){
      this.inProgress = true;
      let formdata = new FormData();
      formdata.append('login',JSON.stringify(this.firstFormGroup.value));
      this.http.postDataByForm('users/user_checking',formdata)
             .then(data => {
                console.log(data);
                this.userStatus  = data;
                this.inProgress = false;
                if(this.userStatus.status==1){
                  this.invalidUser = false;
                }
                else {
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'No contact found!', message: this.userStatus.message, popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.firstFormGroup.reset();
                  });
                }
             });
  }

  loginNow(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('login',JSON.stringify(this.firstFormGroup.value));
    this.http.postDataByForm('users/login',formdata)
            .then(data => {
              console.log(data);
              this.userStatus  = data;
              this.inProgress = false;
              if(this.userStatus.status==1){
                this.invalidUser = false;
                sessionStorage.setItem('user',JSON.stringify(data));
                sessionStorage.setItem('company_name',this.userStatus.data.company_name);
                sessionStorage.setItem('userId',this.userStatus.data.Id);
                sessionStorage.setItem('userCards',this.userStatus.data.userCards);
                sessionStorage.setItem('userIcon',this.userStatus.data.Avatar);
                let franciseeId = this.userStatus.data.Francisee_Id;
                if(this.userStatus.data.User_Role=='Super-Admin' || this.userStatus.data.User_Role=='Admin' || this.userStatus.data.User_Role=='Franchisee'){
                    franciseeId = this.userStatus.data.Id;
                }
                console.log("I am Franchisee : "+franciseeId);
                sessionStorage.setItem('mncCard',this.userStatus.data.isMNC);
                sessionStorage.setItem('isStore',this.userStatus.data.isStore);
                sessionStorage.setItem('franciseeId',franciseeId);
                sessionStorage.setItem('userName',this.userStatus.data.Name);
                sessionStorage.setItem('userRole',this.userStatus.data.User_Role);
                sessionStorage.setItem("userPackId",this.userStatus.data.userPackId);
                sessionStorage.setItem('isLogin','Yes');
                this.firstFormGroup.reset();
                this._router.navigate(['/'+this.userStatus.data.User_Role.toLowerCase()]);
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Credential not found!', message: this.userStatus.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.firstFormGroup.get('password').setValue('');
                });
              }
            });
  }
}
