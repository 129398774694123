<div class="share-form-buttons-container">
    <div class="sharetitle">Share E-Digital V-Card in your network.</div>
    <ul class="share-buttons">
        <li class="share-button">
            <a target="_blank" href="sms:?body={{Company_Url}}">
                SMS
            </a>
        </li>
        <li class="share-button">
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{Company_Url}}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">
                FACEBOOK
            </a>
        </li>
        <li class="share-button">
            <a target="_blank" href="https://twitter.com/intent/tweet?text={{Company_Url}}" data-size="large">
                TWITTER
            </a>
        </li>
        <li class="share-button">
            <a href="https://mail.google.com/mail/?view=cm&to=info@{{webUrl}}&su={{companyName}}&body=Please,Open link for more information. {{Company_Url}}" target="_blank">
                EMAIL
            </a>
        </li>
    </ul>
</div>