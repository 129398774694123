<div>   
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="false" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" 
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Available Themes
                    </div>         
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th>
                            Theme Name
                        </th> -->
                        <th>
                            Theme Image
                        </th>
                        <th>
                            Available Colors
                        </th>
                        <th style="width: 100px;">
                            Status
                        </th>
                        <!-- <th style="width: 120px;">
                            Actions
                        </th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <!-- <td *ngFor="let col of cols">
                            {{record[col.field]}}
                        </td> -->
                        <td style="text-align: center;">
                            <img [src]="imagePath+record.Theme_image" style="max-height:150px;" alt="">
                        </td>
                        <td>
                            <div *ngIf="record.Available_colors.length>0">
                                <div *ngFor="let color of record.Available_colors" style="width: 30px; height: 30px; margin: 10px; float: left;"  [ngStyle]="{'background-color': color.color}" >

                                </div>
                            </div>
                        </td>
                        <td>
                            <button type="button" mat-raised-button color="warn" *ngIf="record.is_Active==0" (click)="changeStatus(record)">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="record.is_Active!=0" (click)="changeStatus(record)">Active</button>
                        </td>
                        <!-- <td>
                            <button  type="button" mat-icon-button (click)="editRecords(record)" color="primary"><mat-icon>edit</mat-icon></button>
                            <button type="button" mat-icon-button (click)="deleteRecords(record)" color="warn"><mat-icon>delete</mat-icon></button>
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>