import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.css']
})
export class AlertpopupComponent implements OnInit {
  title: any = "";
  message: any = "";
  type: any = "popup";
  constructor(@Inject(MAT_DIALOG_DATA) data: any, public dialogRef: MatDialogRef<AlertpopupComponent>) { 
      console.log(data);
      this.title = data.title;
      this.message = data.message;
      this.type = data.popup;
  }

  ngOnInit(): void {
  }
  
  closeCDialog(respo: any){
    this.dialogRef.close(respo);
  }
  closeDialog(){  
    this.dialogRef.close();
  }

}
