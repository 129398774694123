<!-- <div class="bg-dark" style="text-align:center;color: #fff">  
    <h2 class="navbar-brand ">  
      Welcome to {{ title }}!  
    </h2>  
    <select #langSelect (change)="changeLang(langSelect.value)">  
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>  
    </select>  
  </div>  
  <div class="row">  
    <div class="col-md-2">  
      <ul class="list-group">  
        <li class="list-group-item"><a [routerLink]="['/']">{{ 'Home' | translate }}</a></li>  
        <li class="list-group-item"><a [routerLink]="['/employee']">{{ 'Employee' | translate }}</a></li>  
        <li class="list-group-item"><a [routerLink]="['/employeelist']">{{ 'EmployeeList' | translate }}</a></li>  
        <li class="list-group-item"><a href="employeelist">EmployeeList(Reload)</a></li>
      </ul>  
    </div>  
    <div class="col-md-8">  
      <router-outlet></router-outlet>  
    </div>  
  </div> -->
<router-outlet></router-outlet>