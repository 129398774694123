<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-8 pl-0">
        <form [formGroup]="card_filter">
            <div class="row">
                <div class="col-md-4">
                    <label>Created by</label>
                    <mat-select class="form-control" *ngIf="usersFilters" formControlName="user" (selectionChange)="filterRecord()">
                        <mat-option value="">- All</mat-option>
                        <mat-option *ngFor="let users of usersFilters" [value]="users.Id">
                            {{users.Contact_No}} - {{users.Name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-2">
                    <label>Status</label>
                    <mat-select class="form-control" formControlName="status" (selectionChange)="filterRecord()">
                        <mat-option value="">- All</mat-option>
                        <mat-option value="1">Active</mat-option>
                        <mat-option value="0">Inactive</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-2">
                    <label>Payment Status</label>
                    <mat-select class="form-control" formControlName="payment" (selectionChange)="filterRecord()">
                        <mat-option value="">- All</mat-option>
                        <mat-option value="1">Paid</mat-option>
                        <mat-option value="0">Upaid</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-4 text-right" *ngIf="userRole!='Client' || totalRecords<cardCount" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
        <div style="clear: both; height: 15px;"></div>
        <button mat-raised-button color="primary" (click)="createCard()"><mat-icon>add_circle</mat-icon> Create New Card</button>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allCards">
        <p-table #dt [columns]="cols" [value]="allCards" [lazy]="false" (onLazyLoad)="loadCarsLazy($event)"
        [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" [loading]="loading"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
            <ng-template pTemplate="caption">
                <div style="font-size: 20px;">
                    Card Listing
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="text-align: left">        
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ui-helper-clearfix" style="text-align: right">
                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                        </div>
                    </div>
                </div>               
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        Card Id
                    </th>
                    <th>
                        Company Name
                    </th>
                    <th style="width: 150px;">
                        Reg. Phone No.
                    </th>
                    <th style="width: 130px;">
                        Card Made By
                    </th>
                    <th style="width: 120px;">
                        Register Date
                    </th>
                    <th style="width: 120px;">
                        Expiry Date
                    </th>
                    <th style="width: 100px;" *ngIf="userRole=='Client'">
                        Status
                    </th>
                    <th style="width: 100px;" *ngIf="userRole!='Client'">
                        Status
                    </th>
                    <th style="width: 120px;">
                        Actions
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cards>
                <tr>
                    <td>
                        <button 
                        #tooltip="matTooltip"
                        matTooltip="Copy Card Link"
                        matTooltipPosition="below"
                        matTooltipHideDelay="100"
                        mat-icon-button color="primary" (click)="copied(cards)" target="_blank">
                            <mat-icon>insert_link</mat-icon>
                        </button>
                        {{cards.Company_Url}}
                    </td>
                    <td *ngFor="let col of cols">
                        {{cards[col.field]}}
                    </td>
                    <td *ngIf="userRole=='Client'">
                        <button type="button" mat-raised-button color="warn" *ngIf="cards.is_Active==0">Inactive</button>
                        <button type="button" mat-raised-button color="primary" *ngIf="cards.is_Active!=0">Active</button>
                    </td>
                    <td *ngIf="userRole!='Client'">
                        <button type="button" mat-raised-button color="warn" *ngIf="cards.is_Active==0" (click)="changeStatus(cards)">Inactive</button>
                        <button type="button" mat-raised-button color="primary" *ngIf="cards.is_Active!=0" (click)="changeStatus(cards)">Active</button>
                    </td>
                    <td>
                        <button type="button" 
                        #tooltip="matTooltip"
                        matTooltip="Edit Card"
                        matTooltipPosition="below"
                        matTooltipHideDelay="100" mat-icon-button (click)="editRecords(cards)" color="primary"><mat-icon>edit</mat-icon></button>
                        <button 
                        #tooltip="matTooltip"
                        matTooltip="Make copy Card"
                        matTooltipPosition="below"
                        matTooltipHideDelay="100"
                        *ngIf="userRole!='Client'" type="button" mat-icon-button (click)="copycard(cards)"><mat-icon>file_copy</mat-icon></button><br>
                        <button 
                        #tooltip="matTooltip"
                        matTooltip="Delete Card"
                        matTooltipPosition="below"
                        matTooltipHideDelay="100"
                        *ngIf="userRole!='Client'" type="button" mat-icon-button (click)="deleteCard(cards)" color="warn"><mat-icon>delete</mat-icon></button>
                        <button 
                        #tooltip="matTooltip"
                        matTooltip="Subscription Card"
                        matTooltipPosition="below"
                        matTooltipHideDelay="100"
                        *ngIf="userRole!='Client'" type="button" mat-icon-button (click)="clientPay(cards)" color="primary"><mat-icon>payments</mat-icon></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                There are {{allCards?.length}} Records
            </ng-template>
        </p-table>
    </div>
</div>