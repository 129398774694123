<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSidebar()" *ngIf="usertype!='client'">
            <mat-icon>menu</mat-icon>
        </button>
        <span style="padding-right: 15px;" class="mobileTxt">{{company_name}}</span><span class="hidden-sm-down" style="padding-left: 15px; border-left: 4px solid #fff;">{{userRole}} Dashboard</span>
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" style="padding-top: 15px;">
            <ul fxLayout="row" fxLayoutGap="20px"> 
                <!-- <li>
                    <button mat-icon-button>
                        <mat-icon>settings</mat-icon>
                    </button>
                </li> -->
                <li>
                    <button mat-icon-button routerLink="/{{usertype}}/support">
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>assignment_ind</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">                        
                        <div class="profile-card bgColor text-center">
                            <!-- <img [src]="avatar" alt="Go-digicard.com"> -->
                            <div class="header">
                                <h3 class="welcomeh2">Welcome <strong>{{userName}}</strong></h3>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <button mat-menu-item routerLinkActive="list-item-active" routerLink="/{{usertype}}/my-profile">
                            <mat-icon>person_outline</mat-icon>
                            My Profile
                        </button>  
                        <button mat-menu-item routerLinkActive="list-item-active" routerLink="/{{usertype}}/change-password">
                            <mat-icon>visibility</mat-icon>
                            Change Password
                        </button>  
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            Sign out
                        </button>  
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>