import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { SharedModule } from './shared/shared.module';
import { BasicModule } from './basic/basic.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling'

// components
import { CopycardComponent } from './common/copycard/copycard.component';
import { AlertpopupComponent } from './common/alertpopup/alertpopup.component';
import { SpecialityComponent } from './common/speciality/speciality.component';
import { BusinessComponent } from './common/business/business.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { AccountComponent } from './modules/account/account.component';
import { LanguageComponent } from './common/language/language.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectDropDownModule } from 'ngx-select-dropdown'

import { BnNgIdleService } from 'bn-ng-idle';
import { RecyclerComponent } from './modules/recycler/recycler.component';
import { AffiliateComponent } from './modules/affiliate/affiliate.component';
import { CreateticketComponent } from './modules/createticket/createticket.component'; 

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/','.json');
}

// import { FontPickerModule } from 'ngx-font-picker';
// import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
// import { FontPickerConfigInterface } from 'ngx-font-picker';

// const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
//     // Change this to your Google API key
//     apiKey: 'AIzaSyC44eejOb0HgOowKH2GpexUUWBM6Gs1n6k'
// };

@NgModule({
  declarations: [
    AppComponent,    
    AlertpopupComponent, 
    CopycardComponent, 
    SpecialityComponent, 
    BusinessComponent, 
    PaymentComponent, 
    AccountComponent, 
    LanguageComponent, CreateticketComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule,  
    BasicModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    ScrollingModule,
    SelectDropDownModule,
    // FontPickerModule
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    BnNgIdleService
    // {
    //   provide: FONT_PICKER_CONFIG,
    //   useValue: DEFAULT_FONT_PICKER_CONFIG
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
