import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalLibraryService } from '../../util';

declare let Razorpay: any;

@Component({
  selector: 'app-payhistory',
  templateUrl: './payhistory.component.html',
  styleUrls: ['./payhistory.component.css']
})
export class PayhistoryComponent implements OnInit {
  userFormgroup: FormGroup;
  allRecords: any = [];
  packages: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  newRespo: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  openUser: boolean = false;
  editId: any = 0;
  inProgress: boolean = false;
  response: any;
  user_ID: any = 0;
  packAmount: any = 0;
  Subscribe_Years: any = [1,2,3,5];
  selected: any = "1";
  userRecords: any;
  exportColumns: any[];
  userRole: any = "Client";
  pack_Json: any;
  payType: any = "newpack";
  packageJSONArr: any = [];
  packYear: any;
  user_Name: any = "";
  card_Id: any = "";
  isPayRazor: boolean = false;
  razorOrderNo: any;
  selPackage: any;

  name: any = 'Angular';
  razorResponse: any;
  razorpayResponse: any;
  showModal: boolean = false;
  currency: any = "INR";
  currency_symbol: any = "₹";

  RAZORPAY_OPTIONS = {
    // "key": "rzp_live_rXh0xXku4bQAaz",
    "key": "rzp_test_rpJfXZ1PAnc6Hy",
    "amount": "", // Example: 2000 paise = INR 20
    "name": "E-Digital Card",
    "description": "",
    "image": "https://e-digicard.com/web-services/company-profile/5f1698da68bcd-1595316442-5f1698da68bd1.png",// COMPANY LOGO
    "prefill": {
        "name": "", // pass customer name
        "email": '',// customer email
        "contact": '' //customer phone no.
    },
    "notes": {
        "address": "" //customer address 
    },
    "theme": {
        "color": "#00bfd8" // screen color
    }
  };

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _route: Router,
    private razorpayService: ExternalLibraryService, 
    private cd:  ChangeDetectorRef
   ) {

      // this.currency = sessionStorage.getItem("currency_code");
      // this.currency_symbol = sessionStorage.getItem("currency_symbol");

      this.user_ID = sessionStorage.getItem('userId');
      this.route.params.subscribe( 
        params => {
          if(params.userId){
            let allParams = params.userId.split('|');
            this.user_ID = allParams[0];
            // this.user_Name = allParams[1];
            this.card_Id = allParams[1];
            this.getCardDetails(this.card_Id);
          }
        }
      );
      if(sessionStorage.getItem("package")){
          let packName = JSON.parse(sessionStorage.getItem("package"));
          this.selPackage = packName.PackName;
          console.log(this.selPackage);
      }
      this.userRole = sessionStorage.getItem('userRole');
      this.userRecords = JSON.parse(sessionStorage.getItem('user'));
      let franchiseeData = JSON.parse(sessionStorage.getItem("whiteLabel"));
      console.log(this.userRecords);
      if(this.userRole=='Client'){
          this.createRecord();
          // this.RAZORPAY_OPTIONS.theme.color = franchiseeData.theme_color;
          this.RAZORPAY_OPTIONS.image = this.http.serviceUrl+"company-profile/"+franchiseeData.franchisee_logo;
          this.RAZORPAY_OPTIONS.name = franchiseeData.Name;
          this.RAZORPAY_OPTIONS.key = franchiseeData.razor_Live_Key;
          this.RAZORPAY_OPTIONS.prefill.name = this.userRecords.data.Name;
          this.RAZORPAY_OPTIONS.prefill.email = this.userRecords.data.Email_Id;
          this.RAZORPAY_OPTIONS.prefill.contact = this.userRecords.data.Contact_No;
          this.RAZORPAY_OPTIONS.notes.address = this.userRecords.data.Address;
      }
    }
  getCardDetails(card_Id: any){
    this.http.getDataByPage('companies/getCompany/'+card_Id)
             .then((data: any) => {
                console.log(data);
                this.user_Name = data.records.company.Company_Name;
             });
  }
  public proceed() {
    this.RAZORPAY_OPTIONS.amount = (parseInt(this.userFormgroup.get('TotalAmount').value)*100).toString();
    this.RAZORPAY_OPTIONS.description = "Subscription for " + this.packYear + " Year with amount "+this.userFormgroup.get('TotalAmount').value;
    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    // this.showPopup();
    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
    razorpay.open();
    let clock = setInterval(() => {
        this.inProgress = false;
        if(this.razorOrderNo){
            this.saveRecord();
            clearInterval(clock);
        }
     }, 5000); // set it every one seconds}
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
    if(response){
        this.razorOrderNo = response.razorpay_payment_id;
        // this.saveRecord();
    }
    this.razorpayResponse = `Razorpay Response`;
    this.inProgress = true;
    this.cd.detectChanges();
  }
  ngOnInit(): void {

    this.razorpayService
        .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
        .subscribe();

    this.userFormgroup = this._formBuilder.group({
      Pack_Id: ['', Validators.required],
      // Pack_Amount: ['', Validators.required],
      Subscribe_Years: '',
      Francise_Amount: '',
      WL_amount: '',
      // pack_Json: ['',Validators.required],
      TotalAmount: ['', Validators.required],
    });
    this.initTable();
    this.getDetails();
    this.getPackages();
  }
  initTable(){
    this.cols = [
      { field: 'Id', header: 'Id' },
      { field: 'PackName', header: 'Pack Name' },
      // { field: 'Pack_Amount', header: 'Pack_Amount' },
      { field: 'Subscribe_Years', header: 'Subscribe_Years' },
      { field: 'TotalAmount', header: 'TotalAmount' },
      { field: 'Payment_details', header: 'Payment_details' },
      { field: 'Paid_date', header: 'Paid_date' },
      { field: 'Expiry_date', header: 'Expiry_date' },
    ];
  }
  saveRecord(){
    let records = this.userFormgroup.value;
    records.packYear = this.packYear;
    let action = 'Created';
    if(this.user_ID!==0){
      records.User_Id = this.user_ID;
      records.Francisee_Id = sessionStorage.getItem('franciseeId');
    }
    if(this.razorOrderNo){
      records.razorOrderNo = this.razorOrderNo;
      records.Payment_details = this.RAZORPAY_OPTIONS.description+" Payment Reference No. #"+this.razorOrderNo;
    }
    let data = JSON.stringify(records);
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('subscription',data);
    this.http.postDataByForm('subscription/saveRecord',formdata)
            .then(data => {
              this.inProgress = false;
              this.response = data;
              console.log(this.response);
              if(this.response.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' success!', message: this.response.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.getDetails();
                    this.userFormgroup.reset();
                    this.razorOrderNo = null;
                    if(this.editId){
                        this.openUser = false;
                        this.editId = 0;
                    }
                    if(this.userRole=='Client'){
                      this.backtoDashboard();
                    }
                });
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: action+' fail!', message: this.response.message,popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  console.log(result);
                });
              }
            });
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  backtoDashboard(){
    this._route.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase());
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  getDetails(){
    let formdata = new FormData();
    formdata.append('User_Id',this.user_ID);
    this.http.postDataByForm('subscription/get_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            // this.Subscribe_Years = this.respo.data.Subscribe_Years;
            this.allRecords = this.respo.data.records;
            this.payType = this.allRecords.length>0?"renewpack":"newpack";
            this.totalRecords = this.datasource.length;
          }
        });
  }
  getPackages(){
    let formdata = new FormData();
    if(this.selPackage){
      formdata.append('packName',this.selPackage);
    }
    formdata.append('currency',this.currency);
    this.http.postDataByForm('subscription/get_packages',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.packages = this.respo.data.records;
            this.userFormgroup.get('Pack_Id').setValue(this.packages[0].Id);
            this.fetchPackdetails();
          }
        });
  }
  createRecord(){
      this.openUser = true;
  }
  closeform(){
    this.openUser = false;
  }
  fetchPackdetails(){
      console.log(this.userFormgroup.get('Pack_Id').value);
      let packId = this.userFormgroup.get('Pack_Id').value;
      this.packages.forEach(element => {
          if(packId==element.Id){
            console.log(element);
              let packJson = JSON.parse(element.amountJson);
              if(this.payType=="renewpack"){
                this.packageJSONArr = packJson.renewpack;
                this.Subscribe_Years = packJson.renewpack;
              }
              else {
                this.packageJSONArr = packJson.newpack;
                this.Subscribe_Years = packJson.newpack;
              }
              this.userFormgroup.get('Subscribe_Years').setValue(0);
              this.calculateTotal();
          }
      });
  }
  calculateTotal(){
    console.log(this.userFormgroup.get('Subscribe_Years').value);
    let subscribeYr = (this.userFormgroup.get('Subscribe_Years').value=='')?0:this.userFormgroup.get('Subscribe_Years').value;
    // this.packageJSONArr.forEach(element => {
        // if(subscribeYr==element.Year){
            console.log(this.Subscribe_Years[subscribeYr]);
            let packAmount = this.Subscribe_Years[subscribeYr].PackAmount;
            this.packYear = this.Subscribe_Years[subscribeYr].Year;
            this.userFormgroup.get('TotalAmount').setValue(packAmount);
            this.userFormgroup.get('Francise_Amount').setValue(this.Subscribe_Years[subscribeYr].Franchise);
            this.userFormgroup.get('WL_amount').setValue(this.Subscribe_Years[subscribeYr].WL);
    //     }
    // });
    // let totalAmount = parseFloat(this.packAmount)*parseFloat(subscribeYr);
  }
}
