import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './basic/login/login.component';
import { CreatecardComponent } from './modules/createcard/createcard.component';
import { StartcardComponent } from './modules/startcard/startcard.component';
import { CardlistingComponent } from './modules/cardlisting/cardlisting.component';
import { MyprofileComponent } from './modules/myprofile/myprofile.component';
import { ChangepasswordComponent } from './modules/changepassword/changepassword.component';
import { InquiryComponent } from './modules/inquiry/inquiry.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { EmployeeComponent } from './modules/employee/employee.component';
import { PayhistoryComponent } from './modules/payhistory/payhistory.component';
import { FranchiseComponent } from './modules/franchise/franchise.component';
import { AdminComponent } from './modules/admin/admin.component';
import { ThemesComponent } from './modules/themes/themes.component';
import { AffiliateComponent } from './modules/affiliate/affiliate.component';
import { SupportComponent } from './modules/support/support.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'register/:packageName',
    data: {'register':'Yes'},
    component: LoginComponent
  },
  {
    path: 'affiliate/:userToken',
    component: AffiliateComponent
  },
  {
    path: 'client',
      component: DefaultComponent,
      children: [
        {
          path: '',
          component: DashboardComponent
        },
        {
          path: 'createcard',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId',
          component: StartcardComponent
        },
        {
          path: 'my-card',
          component: CardlistingComponent
        },
        {
          path: 'createcard/:userId/:cardId',
          component: StartcardComponent
        },
        {
          path: 'my-inquiry',
          component: InquiryComponent
        },
        {
          path: 'my-feedback',
          component: FeedbackComponent
        },
        // {
        //   path: 'subscription',
        //   component: PayhistoryComponent
        // },
        {
          path: 'my-profile',
          component: MyprofileComponent
        },
        {
          path: 'change-password',
          component: ChangepasswordComponent
        },
        {
          path: 'subscription/:userId',
          component: PayhistoryComponent
        },
        {
          path: 'support',
          component: SupportComponent
        }
      ]
  },
  {
      path: 'employee',
      component: DefaultComponent,
      children: [
        {
          path: '',
          component: DashboardComponent
        },
        {
          path: 'createcard',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId/:cardId',
          component: StartcardComponent
        },
        {
          path: 'card-listing',
          component: CardlistingComponent
        },
        {
          path: 'card-listing/:type',
          component: CardlistingComponent
        },
        {
          path: 'clients',
          component: ClientsComponent
        },
        {
          path: 'subscription/:userId',
          component: PayhistoryComponent
        },
        {
          path: 'support',
          component: SupportComponent
        }
      ]
  },
  {
    path: 'franchisee',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: 'employees',
        component: EmployeeComponent
      },
      {
        path: 'createcard',
        component: StartcardComponent
      },
      {
        path: 'createcard/:userId',
        component: StartcardComponent
      },
      {
        path: 'createcard/:userId/:cardId',
        component: StartcardComponent
      },
      {
        path: 'card-listing',
        component: CardlistingComponent
      },
      {
        path: 'card-listing/:type',
        component: CardlistingComponent
      },
      {
        path: 'my-profile',
        component: MyprofileComponent
      },
      {
        path: 'change-password',
        component: ChangepasswordComponent
      },
      {
        path: 'inquiry/:companyId',
        component: InquiryComponent
      },
      {
        path: 'feedback/:companyId',
        component: FeedbackComponent
      },
      {
        path: 'subscription/:userId',
        component: PayhistoryComponent
      },
      {
        path: 'support',
        component: SupportComponent
      }
    ]
  },
  {
      path: 'admin',
      component: DefaultComponent,
      children: [
        {
          path: '',
          component: DashboardComponent
        },
        {
          path: 'franchisee',
          component: FranchiseComponent
        },
        {
          path: 'clients',
          component: ClientsComponent
        },
        {
          path: 'employees',
          component: EmployeeComponent
        },
        {
          path: 'themes',
          component: ThemesComponent
        },
        {
          path: 'create-card',
          component: CreatecardComponent
        },
        {
          path: 'createcard',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId/:cardId',
          component: StartcardComponent
        },
        {
          path: 'card-listing',
          component: CardlistingComponent
        },
        {
          path: 'card-listing/:type',
          component: CardlistingComponent
        },
        {
          path: 'my-profile',
          component: MyprofileComponent
        },
        {
          path: 'change-password',
          component: ChangepasswordComponent
        },
        {
          path: 'inquiry/:companyId',
          component: InquiryComponent
        },
        {
          path: 'feedback/:companyId',
          component: FeedbackComponent
        },
        {
          path: 'subscription/:userId',
          component: PayhistoryComponent
        },
        {
          path: 'support',
          component: SupportComponent
        }
      ]
  },
  {
      path: 'super-admin',
      component: DefaultComponent,
      children: [
        {
          path: '',
          component: DashboardComponent
        },
        {
          path: 'admin',
          component: AdminComponent
        },
        {
          path: 'franchisee',
          component: FranchiseComponent
        },
        {
          path: 'clients',
          component: ClientsComponent
        },
        {
          path: 'employees',
          component: EmployeeComponent
        },
        {
          path: 'themes',
          component: ThemesComponent
        },
        {
          path: 'create-card',
          component: CreatecardComponent
        },
        {
          path: 'createcard',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId',
          component: StartcardComponent
        },
        {
          path: 'createcard/:userId/:cardId',
          component: StartcardComponent
        },
        {
          path: 'card-listing',
          component: CardlistingComponent
        },
        {
          path: 'card-listing/:type',
          component: CardlistingComponent
        },
        {
          path: 'my-profile',
          component: MyprofileComponent
        },
        {
          path: 'change-password',
          component: ChangepasswordComponent
        },
        {
          path: 'inquiry/:companyId',
          component: InquiryComponent
        },
        {
          path: 'feedback/:companyId',
          component: FeedbackComponent
        },
        {
          path: 'subscription/:userId',
          component: PayhistoryComponent
        },
        {
          path: 'account',
          component: PayhistoryComponent
        },
        {
          path: 'support',
          component: SupportComponent
        }
      ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
