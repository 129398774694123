<div style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Select Nature of Business</mat-card-title>
    </mat-card-header>
    <h3>Default nature of business list</h3>
    <mat-card-content>
        <ul>
            <li *ngFor="let nature of Business">
                <label>
                    <input type="checkbox" [value]="nature.businessName" [checked]='callfunction(nature.businessName)' (change)="onCheckboxChange($event)" />
                    {{nature.businessName}}
                </label>
            </li>
        </ul>
    <mat-divider></mat-divider>
    <h3>Manual nature of business</h3>
    <div *ngIf="isaddMore">
        <form [formGroup]="formGroup">
            <div formArrayName="natures">
                <div class="row" style="margin: 10px 0px;" *ngFor="let Nature of Natures().controls; let i=index" [formGroupName]="i">
                    <div class="col-md-9">
                        <input type="text" placeholder="Enter Nature of Business" formControlName="businessName" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <button (click)="removeNature(i)" mat-icon-button type="button" class="btn btn-danger"><mat-icon>delete_sweep</mat-icon></button>
                    </div>                        
                </div>
            </div>
        </form>
    </div>
    <button mat-raised-button color="primary" (click)="addOther()">Add Other</button>
    </mat-card-content>
    <button mat-button style="float: right;" color="primary" (click)="closeCDialog()">Done</button>
</div>