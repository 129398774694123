import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.css']
})
export class ThemesComponent implements OnInit {

  allRecords: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  newRespo: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  imagePath: any;
  exportColumns: any[];

  constructor(
      private http: HttpService,
      private dialog: MatDialog
    ) {
      this.imagePath = this.http.serviceUrl+"themes/";
     }

  ngOnInit(): void {
    this.getDetails();
    this.initTable();

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loading = false;
  }
  initTable(){
    this.cols = [
      { field: 'Theme_name', header: 'Theme Name' },
      // { field: 'Theme_image', header: 'Theme  image' },
      // { field: 'Available_colors', header: 'Available_colors' },
    ];
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  
//   exportPdf() {
//     import("jspdf").then(jsPDF => {
//         import("jspdf-autotable").then(x => {
//             const doc = new jsPDF.default(0,0);
//             doc.autoTable(this.exportColumns, this.allBills);
//             doc.save('primengTable.pdf');
//         })
//     })
// }

// exportExcel() {
//     import("xlsx").then(xlsx => {
//         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
//         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
//         this.saveAsExcelFile(excelBuffer, "primengTable");
//     });
// }

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  getDetails(){
    let formdata = new FormData();
    formdata.append('user','Admin');
    this.http.postDataByForm('companies/getThemeList',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allRecords = this.respo.data.themesListing;
            this.totalRecords = this.allRecords.length;
          }
        });
  }
  changeStatus(theme: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of company", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.changeConfirm(theme);
        }
    });
  }
  changeConfirm(theme: any){
    console.log(theme);
    theme.is_Active = theme.is_Active=='0'?'1':'0';
    let formdata = new FormData();
    let firstForm = {
        Id: theme.Id,
        is_Active: theme.is_Active
    };
    let data = JSON.stringify(firstForm);
    formdata.append('theme',data);
    this.http.postDataByForm('companies/editTheme',formdata)
            .then(data => {
                console.log(data);
            });
  }
}
