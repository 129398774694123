<h2 class="dashboardstat" *ngIf="userRole!='Client'">Dashboard Stats
    <button mat-raised-button color="primary" (click)="createCard()" class="float-right hidden-sm-down"><mat-icon>add_circle</mat-icon> Create New Card</button>
</h2>
<mat-divider *ngIf="userRole!='Client'"></mat-divider>
<div class="hidden-md-up" *ngIf="(allCards[0] && moreCards) || userRole!='Client'">
    <div style="clear: both; height: 15px;"></div>
    <button mat-raised-button color="primary" (click)="createCard()" class="float-right" style="margin-right: 15px;"><mat-icon>add_circle</mat-icon> Create New Card</button>
    <div style="clear: both; height: 0px;"></div>
</div>    
<div class="dashheader" *ngIf="userRole=='Client'">
    <div fxLayoutAlign="center center" fxFlexFill *ngIf="!allCards[0]">
        <button mat-raised-button color="primary" (click)="createCard()" class="float-right"><mat-icon>add_circle</mat-icon> Create New Card</button>
    </div>
    <div *ngIf="allCards">
        <div *ngFor="let cards of allCards" style="margin-bottom: 20px;">
    <div fxLayoutAlign="center center" fxFlexFill>
        <mat-card fxFlex="40" fxFlex.sm="50" fxFlex.xs="90" class="mat-card-background">
            <div class="profile-card text-center">
                <img [src]="imgUrl+cards.Company_Logo" *ngIf="cards.Company_Logo" alt="Go-digicard.com">
                <div class="header">
                    <h3 style="padding-bottom: 0px;" class="dashboardstat">{{cards.Company_Name}}</h3>
                    <h4>{{cards.userRegister}}</h4>
                </div>
            </div>
            <div style="margin-bottom: 20px;">
                <div class="menu-items">
                    <mat-icon>person_outline</mat-icon> 
                    <div>
                        <span *ngIf="user.Name==''">Not provided</span><span *ngIf="user.Name!==''">{{user.Name}}</span>
                    </div>   
                </div>
                <div class="menu-items">
                    <mat-icon>phone_iphone</mat-icon> 
                    <div>{{user.Contact_No}}</div>
                </div>
                <div class="menu-items">
                    <mat-icon>payment</mat-icon> 
                    <div>
                        Card Status : <span *ngIf="cards.is_Active=='1'" style="color:green">Active</span><span  *ngIf="cards.is_Active!='1'" style="color:red">Inactive</span>
                    </div>
                </div>
                <div class="menu-items">
                    <mat-icon>account_balance_wallet</mat-icon> 
                    <div>
                        Payment Status : <span *ngIf="cards.is_Paid=='1'" style="color:green">Paid</span><span *ngIf="cards.is_Paid=='0'" style="color:red">Unpaid</span>
                    </div>
                </div>
                <div class="menu-items" *ngIf="cards.is_Paid=='1'">
                    <mat-icon>payment</mat-icon> 
                    <div>
                        Expiry Date : <span style="color:green">{{cards.ExpiryDate}}</span>  
                    </div>
                </div>
                <div class="menu-items" *ngIf="cards.expireDays<60 && cards.is_Paid=='1'">
                    <span style="color:red">Your cards will be expired after <strong style="color:green">{{cards.expireDays}}</strong> days, please, click on <strong style="color:green">Pay Now</strong></span>
                </div>
                <div class="menu-items" *ngIf="cards.is_Paid!=='1'">
                    <span style="color:red">To activate your digital Visiting card for valuable brand, please, click on <strong style="color:green">Pay Now </strong></span>
                </div>
                <button style="float:right;" mat-raised-button color="warn" (click)="clientPay(cards)">
                    <mat-icon style="margin-top: 5px;">payment</mat-icon> Pay Now
                </button>
            </div>
            <div style="clear:both"></div>
            <div class="bottom-bottom text-center" style="margin-top: 20px;">
                <button mat-raised-button color="primary" (click)="editCard(cards)">
                    <mat-icon>create</mat-icon> Edit
                </button>
                <a *ngIf="allCards.length>0" mat-raised-button color="primary" href="{{mainUrl+cards.Company_Url}}" target="_blank">
                    <mat-icon>visibility</mat-icon> View
                </a>
                <button mat-raised-button color="primary" (click)="copied(cards)">
                    <mat-icon>file_copy</mat-icon> Link
                </button>
                <button mat-raised-button color="primary" (click)="shared(cards)" style="margin-left: 0;">
                    <mat-icon>share</mat-icon> Share
                </button>
            </div>
        </mat-card>
    </div>
</div>
</div>
</div>
<div class="dashheader" *ngIf="userRole!='Client'">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="20px" class="stats-card">
        <div fxFlex="40" fxFlex.xs="100" class="counterdiv" color="primary">            
            <div fxLayout="row" class="div-first" fxLayout.xs="column" fxLayoutAlign="space-between" style="margin-bottom: 15px;" fxLayoutGap="20px">
                <mat-card fxFlex="33" color="primary" (click)="pageTransfer('/card-listing')">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.todayCards}}</mat-card-subtitle>
                        <mat-card-title>Today vCards</mat-card-title>
                    </mat-card-header>
                </mat-card>
                <mat-card fxFlex="33">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.expireCount}}</mat-card-subtitle>
                        <mat-card-title>Today Expiry</mat-card-title>
                    </mat-card-header>
                </mat-card>
                <mat-card fxFlex="33" (click)="pageTransfer('/card-listing')">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.monthCards}}</mat-card-subtitle>
                        <mat-card-title>Month vCards</mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>
            <div fxLayout="row"  class="div-second" fxLayout.xs="column" fxLayoutAlign="space-between" style="margin-bottom: 15px;" fxLayoutGap="20px">
                <mat-card fxFlex="{{cardSize}}" (click)="pageTransfer('/clients')">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.clients}}</mat-card-subtitle>
                        <mat-card-title>Clients</mat-card-title>
                    </mat-card-header>
                </mat-card>
                <mat-card fxFlex="{{cardSize}}" (click)="pageTransfer('/card-listing')">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.vCards}}</mat-card-subtitle>
                        <mat-card-title>Total vCards</mat-card-title>
                    </mat-card-header>
                </mat-card>
                <mat-card fxFlex="33" *ngIf="userRole=='Franchisee' || userRole=='Admin' || userRole=='Super-Admin'" (click)="pageTransfer('/employees')">
                    <mat-card-header>
                        <mat-card-subtitle>{{DashboardCount.employees}}</mat-card-subtitle>
                        <mat-card-title>Employee</mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>
        </div>
        <div fxFlex="60" fxFlex.xs="100">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Subscription Expire within Month</mat-card-title>
                    <mat-divider></mat-divider>
                </mat-card-header>
                <mat-card-content>
                    <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
                        <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" 
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                            <ng-template pTemplate="caption">
                                <div style="font-size: 20px;">
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="ui-helper-clearfix" style="text-align: right">
                                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                                        </div>
                                    </div>
                                </div>               
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>
                                        Company Name
                                    </th>
                                    <th>
                                        Contact No
                                    </th>
                                    <th>
                                        Email Id
                                    </th>
                                    <th style="width: 110px;">
                                        Expiry Date
                                    </th>
                                    <th style="width: 70px;">
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record>
                                <tr>
                                    <td *ngFor="let col of cols">
                                        {{record[col.field]}}
                                    </td>
                                    <td>
                                        <button  type="button" mat-icon-button (click)="clientPay(record)" color="primary"><mat-icon>payments</mat-icon></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                There are {{allRecords?.length}} Records
                            </ng-template>
                        </p-table>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
