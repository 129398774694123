<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]='!inProgress'>
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>    
<div>
    <button mat-raised-button color="primary" *ngIf="userRole=='Client'" (click)="backtoDashboard()">
        <mat-icon>keyboard_backspace</mat-icon> Back to Card Dashboard
    </button>
</div>
<div *ngIf="openUser" class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-10 offset-md-1">
        <h2>Subscribe Now</h2>
        <mat-divider></mat-divider>
    </div>
    <div class="col-md-10 offset-md-1">
        <mat-card class="mat-card-background">
            <form [formGroup]="userFormgroup">
                <div class="row">
                <div class="form-group col-md-3">
                    <label>Package Name*</label>
                    <mat-select class="form-control" name="Pack_Id" (selectionChange)="fetchPackdetails()" formControlName='Pack_Id'>
                        <mat-option *ngFor="let package of packages" [value]="package.Id">
                            {{package.PackName}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="form-group col-md-3">
                    <label>No of Years*</label>
                    <mat-select [(value)]="selected" class="form-control" name="Subscribe_Years" (selectionChange)="calculateTotal()" formControlName='Subscribe_Years'>
                        <mat-option *ngFor="let sb_year of Subscribe_Years; let i=index" [value]="i">
                            <!-- {{sb_year}} -->
                            {{sb_year.Year}} Years - {{currency_symbol}}{{sb_year.PackAmount}}
                        </mat-option>
                    </mat-select>
                </div>
                <!-- <div class="form-group col-md-3">
                    <label>Package Amount*</label>
                    <input class="form-control" type="number" readonly placeholder="Pack Amount" formControlName="Pack_Amount">
                </div> -->
                <div class="form-group col-md-3">
                    <label>Total Amount*</label>
                    <input class="form-control" type="text" readonly placeholder="TotalAmount" formControlName="TotalAmount">
                </div>
                <div *ngIf="userRole=='Client' && !isPayRazor && !razorOrderNo" class="form-group col-md-3">
                    <label>Click to Pay online</label>
                    <button mat-raised-button color="primary" [disabled]="!userFormgroup.valid" (click)="proceed()">
                        <mat-icon>payment</mat-icon> Pay online<mat-icon>forward</mat-icon>
                    </button>
                </div> 
                <div *ngIf="userRole=='Client' && razorOrderNo" class="form-group col-md-3">
                    <label>Payment Reference No</label>
                    <div>
                        <input class="form-control" type="text" placeholder="Reference No" readonly value="{{razorOrderNo}}">
                    </div>
                </div>            
                <div class="form-group col-md-12 text-right" *ngIf="userRole!=='Client'">
                    <button mat-raised-button color="warn" (click)="closeform()" style="margin-right: 15px;">
                        <mat-icon>close</mat-icon> Close
                    </button>
                    <button mat-raised-button color="primary" [disabled]="!userFormgroup.valid" (click)="saveRecord()">
                        <mat-icon>save</mat-icon> <span *ngIf="editId===0">Submit</span><span *ngIf="editId!==0">Update</span> <mat-icon>forward</mat-icon>
                    </button>
                </div>
                </div>
            </form>   
        </mat-card>
    </div>
    <div style="clear: both;"></div>
</div>
<div>   
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div *ngIf="!openUser" class="col-md-12 text-right"  style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Subscribe Now</button>
        </div>
        <div class="col-md-12" style="padding: 20px 0px; background: #efefef;">
            <div class="row" style="font-size: 18px;">
                <div class="col-md-4" style="margin-left: 20px;">
                    <strong>Name :</strong> {{user_Name}}
                </div>
                <div class="col-md-4">
                    <strong>Card Id :</strong> {{card_Id}}
                </div>
            </div>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" 
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Subscription History
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">        
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>               
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 50px;">
                            Id
                        </th>
                        <th>
                            Pack Name
                        </th>
                        <th>
                            Subscribe Years
                        </th>
                        <th style="width: 200px;">
                            Total Amount
                        </th>
                        <th>
                            Payment Details
                        </th>
                        <th style="width: 100px;">
                            Paid Date
                        </th>
                        <th style="width: 120px;">
                            Expiry Date
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td *ngFor="let col of cols">
                            {{record[col.field]}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>