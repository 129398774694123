<div *ngIf="openAdmin" class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-10 offset-md-1">
        <h2>Create Admin</h2>
        <mat-divider></mat-divider>
    </div>    
    <div class="col-md-10 offset-md-1">
        <mat-card class="mat-card-background">
            <form [formGroup]="adminFormgroup">
                <div class="row">
                <div class="form-group col-md-4" *ngIf='userRole=="Super-Admin"'>
                    <label>Whitelabel Url</label>
                    <input class="form-control" type="text" placeholder="White label" formControlName="White_label">
                </div>
                <div class="form-group col-md-4" *ngIf='userRole=="Super-Admin"'>
                    <label>Website Main Url</label>
                    <input class="form-control" type="text" placeholder="Main Url" formControlName="Main_Url">
                </div>
                <div class="form-group col-md-4" *ngIf='userRole=="Super-Admin"'>
                    <label>Razor Live Key</label>
                    <input class="form-control" type="text" placeholder="Razor Live Key" formControlName="razor_Live_Key">
                </div>
                <div class="form-group col-md-4">
                    <label>Company Name</label>
                    <input class="form-control" type="text" placeholder="Company Name" formControlName="company_name">
                </div>
                <div class="form-group col-md-4">
                    <label>Name*</label>
                    <input class="form-control" type="text" placeholder="Name" formControlName="Name">
                </div>
                <div class="form-group col-md-4">
                    <label>Contact No*</label>
                    <input class="form-control" oninput="this.value=this.value.replace(/[^0-9]/g,'');" type="tel" placeholder="Contact No" formControlName="Contact_No">
                </div>
                <div class="form-group col-md-4">
                    <label>Password*</label>
                    <input class="form-control" type="password" placeholder="Password" formControlName="Password">
                </div>
                <div class="form-group col-md-4">
                    <label>Address</label>
                    <textarea class="form-control" placeholder="Address" formControlName="Address"></textarea>
                </div>
                <div class="form-group col-md-4">
                    <label>City*</label>
                    <input class="form-control" type="text" placeholder="City" formControlName="City">
                </div>            
                <div class="form-group col-md-4">
                    <label>Email Id</label>
                    <input type="email" class="form-control"  placeholder="Email Id" formControlName="Email_Id" required>
                </div>
                <div class="form-group col-md-4">
                    <label>User Role</label>
                    <mat-select class="form-control" formControlName="User_Role">
                        <mat-option value="">- User Role</mat-option>
                        <mat-option *ngFor="let users of userRoles" [value]="users">
                            {{users}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="form-group col-md-2" *ngIf='userRole=="Super-Admin"'>
                    <label>Is International Allow</label>
                    <mat-select class="form-control" formControlName="isMNC">
                        <mat-option value="0">- Not Allow</mat-option>
                        <mat-option value="1">- Allow</mat-option>
                    </mat-select>
                </div>                
                <div class="form-group col-md-2" *ngIf='userRole=="Super-Admin"'>
                    <label>is Store</label>
                    <mat-select class="form-control" formControlName="isStore">
                        <mat-option value="0">- Not Allow</mat-option>
                        <mat-option value="1">- Allow</mat-option>
                    </mat-select>
                </div>
                <div class="form-group col-md-12 text-right">
                    <button mat-raised-button color="warn" (click)="closeform()" style="margin-right: 15px;">
                        <mat-icon>close</mat-icon> Close
                    </button>
                    <button mat-raised-button color="primary" [disabled]="!adminFormgroup.valid" (click)="saveRecord()">
                        <mat-icon>save</mat-icon> <span *ngIf="editId===0">Submit</span><span *ngIf="editId!==0">Update</span> <mat-icon>forward</mat-icon>
                    </button>
                </div>
                </div>
            </form>   
        </mat-card>
    </div>
    <div style="clear: both;"></div>
</div>
<div>   
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div *ngIf="!openAdmin" class="col-md-12 text-right"  style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Create Admin</button>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" 
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Admin List
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">        
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>               
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 50px;">
                            Id
                        </th>
                        <th>
                            Name
                        </th>
                        <th style="width: 150px;">
                            Phone No.
                        </th>
                        <th>
                            Email Id
                        </th>
                        <th style="width: 200px;">
                            Address
                        </th>
                        <th style="width: 100px;">
                            City
                        </th>
                        <th style="width: 120px;">
                            Register Date
                        </th>
                        <th style="width: 100px;">
                            Status
                        </th>
                        <th style="width: 120px;">
                            Actions
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td *ngFor="let col of cols">
                            {{record[col.field]}}
                        </td>
                        <td>
                            <button type="button" mat-raised-button color="warn" *ngIf="record.isActive==0" (click)="changeStatus(record)">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="record.isActive!=0" (click)="changeStatus(record)">Active</button>
                        </td>
                        <td>
                            <button  type="button" mat-icon-button (click)="editRecords(record)" color="primary"><mat-icon>edit</mat-icon></button>
                            <button type="button" mat-icon-button (click)="deleteRecords(record)" color="warn"><mat-icon>delete</mat-icon></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>