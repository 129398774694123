import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  serviceUrl: any = "https://myedigicard.in/v1-service/";
  pageUrl: any = "https://myedigicard.in/";

  header: any = null;
  constructor(private http: HttpClient) { 
      this.header = new HttpHeaders({
        'Authorization': 'token L32rJpcClAqGtCmTMwQN3sx26fU4qxqoGkMi07efGkI'
      });
  }

  getDataByPage(pageName: any) {
    return new Promise(resolve => {
      this.http.get(this.serviceUrl+pageName)
          .subscribe(data => {
            resolve(data);
          }, err => {
            console.log(err);
          });
    });
  }
  getDataByUrl(pageName: any) {
    return new Promise(resolve => {
      this.http.get(pageName)
          .subscribe(data => {
            resolve(data);
          }, err => {
            console.log(err);
          });
    });
  }
  postDataByJson(pageName: any, pageData: any){
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, JSON.stringify(pageData))
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
    });
  }
  postDataByForm(pageName: any, pageData: any){
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, pageData)
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
    });
  }
}
