import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
 company_name: any = "";   
 avatar: any = "go-digital.png";
 userName: any = "Go DigitalCard";
 userRole: any = "Admin";
 @Output() toggleSidebarForMenu: EventEmitter<any> = new EventEmitter<any>();
 usertype: any = "client";
  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private http: HttpService
  ) { 
      if(!sessionStorage.getItem('isLogin')){
        this._router.navigate(['/']);
      }
      let franchiseeData = JSON.parse(sessionStorage.getItem("whiteLabel"));
      if(franchiseeData){
        this.company_name = franchiseeData.Name;           
      }
      this.userName = sessionStorage.getItem('userName');
      this.userRole = sessionStorage.getItem('userRole');
      let avatar = sessionStorage.getItem('userIcon')!='' && sessionStorage.getItem('userIcon')!='undefined'?sessionStorage.getItem('userIcon'):this.avatar;
      this.avatar = this.http.serviceUrl+"company-profile/"+avatar;
  }

  ngOnInit(): void {
    this.usertype = sessionStorage.getItem('userRole').toLowerCase();
  }
  toggleSidebar(){
    this.toggleSidebarForMenu.emit();
  }
  logout(){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to exit !', message: "Are you sure, You want to logout from system.", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('isLogin');
            this._router.navigate(['/']);
        }
    });
  }
}
