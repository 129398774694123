import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  allRecords: any = [];
  packages: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  newRespo: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  openUser: boolean = false;
  editId: any = 0;
  inProgress: boolean = false;
  response: any;
  userRecords: any;
  exportColumns: any[];
  userRole: any = "";
  cardSize: any = 50;
  avatar: any;
  user: any = [];
  allCards: any = [];
  serviceUrl: any;
  DashboardCount: any = {
    todayCards: 0,
    expireCount: 0,
    monthCards: 0,
    employees: 0,
    clients: 0,
    vCards: 0
  };
  mainUrl: any = "";
  imgUrl: any = "";
  moreCards: boolean = false;

  constructor(
    private http: HttpService,
    private route: Router,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
   ) {
        this.userRole = sessionStorage.getItem('userRole');
        if(this.userRole=='Francisee' || this.userRole=='Admin' || this.userRole=='Super-Admin'){
          this.cardSize = 33;
        }
        this.serviceUrl = this.http.pageUrl;
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.user = this.user.data;
        console.log(this.user);
        sessionStorage.removeItem('tabNo');
    }

  ngOnInit(): void {
    this.initTable();
    this.getDetails();
    if(this.userRole=='Client'){
        this.getCardDetails();
    }
    this.getCounter();
    this.mainUrl = sessionStorage.getItem('mainURL');
    this.DashboardCount.expireCount = this.totalRecords;
  }
  initTable(){
    this.cols = [
      { field: 'Company_Name', header: 'Company Name' },
      { field: 'Contact_No', header: 'Contact No' },
      { field: 'Email_Id', header: 'Email Id' },
      { field: 'Expiry_Date', header: 'Expiry Date' },
    ];
  }
  copied(cards: any){
    let pageUrl = sessionStorage.getItem('mainURL')+cards.Company_Url;
    this.clipboard.copy(pageUrl);
    this._snackBar.open(pageUrl, 'copied successfully.', {
      duration: 2000,
    });
  }
  shared(cards: any){
    // let colors = ['Frodo', 'Bilbo'];
    let sheet = this._bottomSheet.open(SharePage,
      {
        ariaLabel: 'Share Card Social Media',
        panelClass: 'page-plat-color',
        data: { cards: JSON.stringify(cards)},  
    });
    sheet.afterDismissed().subscribe((str) => {
    });
  }
  createCard(){
    this.route.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+"/createcard");
  }
  getCardDetails(){
    let formdata = new FormData();
    formdata.append('user',sessionStorage.getItem('userRole'));
    formdata.append('userId',sessionStorage.getItem('userId'));
    formdata.append('franciseeId',sessionStorage.getItem('franciseeId'));
    this.http.postDataByForm('companies/get_card_listing',formdata)
        .then(data => {
          // console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            this.datasource = this.respo.data.records;
            this.allCards = [];
            if(this.datasource.length>0){
              this.datasource.forEach(element => {
                element.expireDays = this.calculateDiff(element);
                this.allCards.push(element);
              });
            }
            if(this.allCards.length < parseInt(this.user.userCards)){
                this.moreCards = true;
            }            
            this.avatar = this.http.serviceUrl+"company-profile/"+this.allCards[0].Company_Logo;
            this.imgUrl = this.http.serviceUrl+"company-profile/";
          }
          console.log(this.allCards);
        });
  }
  calculateDiff(data){
    let date = new Date(data.ExpiryDate);
    let currentDate = new Date();
    let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
  editCard(cards: any){
      this.route.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+'/createcard/'+cards.User_Id+"/"+cards.Company_Url);
  }
 // Data tables
 next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  getDetails(){
    let formdata = new FormData();
    formdata.append('userRole',this.userRole);
    formdata.append('Francisee_Id',sessionStorage.getItem('franciseeId'));
    this.http.postDataByForm('subscription/get_expiryuser',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allRecords = this.respo.data.records;
            this.totalRecords = this.datasource.length;
          }
        });
  }
  pageTransfer(pageName: any){
    this.route.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+pageName);
  }
  getCounter(){
    let formdata = new FormData();
    formdata.append('userRole',this.userRole);
    formdata.append('userId',sessionStorage.getItem('userId'));
    formdata.append('Francisee_Id',sessionStorage.getItem('franciseeId'));
    this.http.postDataByForm('companies/get_counter',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.DashboardCount = this.respo.data;
          }
        });
  }
  clientPay(record: any){
    this.route.navigateByUrl("/"+sessionStorage.getItem('userRole').toLowerCase()+"/subscription/"+record.Id+"|"+record.Company_Url);
  }
}

// Open color plat
@Component({
  selector: 'share',
  templateUrl: 'share.html',
  styleUrls: ['./dashboard.component.css']
})
export class SharePage {
  cards: any;
  companyName: any = "";
  webUrl: any;
  Company_Url: any;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
    private _bottomSheetRef: MatBottomSheetRef<SharePage>
  ) {
    console.log(data.cards);
    this.cards = JSON.parse(data.cards);
    this.companyName = this.cards.Company_Name;
    this.webUrl = sessionStorage.getItem('mainURL');
    this.Company_Url = this.webUrl+this.cards.Company_Url;

  }
  dismissPopup()
  {
    this._bottomSheetRef.dismiss();
  }
}