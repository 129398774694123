<h2>
    {{themeLabel}}
    <mat-dialog-actions align="end">
        <button style="float: right;" mat-raised-button color="primary" (click)="dismissPopup()" ><mat-icon>highlight_off</mat-icon> Done</button>
    </mat-dialog-actions>
</h2>
<mat-divider></mat-divider>
<div *ngIf="backgroundList.length>0" style="max-width: 600px;">
    <div *ngFor="let backgroun of backgroundList" style="width: 33%; padding: 2%; float: left;">
        <a mat-list-item [ngClass]="{'active':backgroun.background===activeBgImage}" (click)="selectBgImage(backgroun.background)">
            <img src="{{themeUrl+backgroun.background}}" style="max-height: 100px; width: 100%;" />
        </a>
    </div>
</div>
<div style="clear: both;"></div>