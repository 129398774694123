<mat-nav-list>
    <div *ngIf="userRole=='Super-Admin'">
        <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin"><mat-icon>view_module</mat-icon> Dashboard</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/admin"><mat-icon>supervisor_account</mat-icon> Admin</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/franchisee"><mat-icon>how_to_reg</mat-icon> Franchisee</button> 
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/employees"><mat-icon>directions_walk</mat-icon> Employee</button>   
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/clients"><mat-icon>assignment_ind</mat-icon> Clients</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/themes"><mat-icon>color_lens</mat-icon> Themes</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/card-listing"><mat-icon>recent_actors</mat-icon> Card Listing</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/super-admin/account"><mat-icon>account_balance_wallet</mat-icon> Account</button>
    </div>
    <div *ngIf="userRole=='Admin'">
        <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/admin"><mat-icon>view_module</mat-icon> Dashboard</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/admin/franchisee"><mat-icon>how_to_reg</mat-icon> Franchisee</button> 
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/admin/employees"><mat-icon>directions_walk</mat-icon> Employee</button>   
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/admin/clients"><mat-icon>assignment_ind</mat-icon> Clients</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/admin/card-listing"><mat-icon>recent_actors</mat-icon> Card Listing</button>
    </div>
    <div *ngIf="userRole=='Franchisee'">
        <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/franchisee"><mat-icon>view_module</mat-icon> Dashboard</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/franchisee/clients"><mat-icon>assignment_ind</mat-icon> My Clients</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/franchisee/employees"><mat-icon>directions_walk</mat-icon> Employee</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/franchisee/card-listing"><mat-icon>recent_actors</mat-icon> Card Listing</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/franchisee/account"><mat-icon>account_balance_wallet</mat-icon> Subscription</button>
    </div>
    <div *ngIf="userRole=='Employee'">
        <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/employee"><mat-icon>view_module</mat-icon> Dashboard</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/employee/clients"><mat-icon>assignment_ind</mat-icon> Clients</button>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/employee/card-listing"><mat-icon>recent_actors</mat-icon> Card Listing</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/employee/clients"><mat-icon>account_balance_wallet</mat-icon> Subscription</button>
    </div>
    <div *ngIf="userRole=='Client'">
        <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/client">Dashboard</button>
        <mat-divider></mat-divider>
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/client/my-card"><mat-icon>recent_actors</mat-icon> My Card</button>
            <!-- <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/client/my-inquiry">My Inquiries</button>    
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/client/my-feedback">My Feedback</button> -->
            <button mat-menu-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/client/subscription"><mat-icon>person_outline</mat-icon> My Subscription</button>
    </div>
</mat-nav-list>