import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { MatDialog } from '@angular/material/dialog';
import { BusinessComponent } from 'src/app/common/business/business.component';
import { SpecialityComponent } from 'src/app/common/speciality/speciality.component';

interface prefixs {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-startcard',
  templateUrl: './startcard.component.html',
  styleUrls: ['./startcard.component.css']
})
export class StartcardComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fouthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixFormGroup: FormGroup;
  sevenFormGroup: FormGroup;
  eightFormGroup: FormGroup;
  nineFormGroup: FormGroup;
  tenFormGroup: FormGroup;
  elevenFormGroup: FormGroup;
  customise_headers: FormGroup;
  companyseo: FormGroup;

  tab: any = 1;
  imagePath: any = "";
  previewURL: any = "";
  themes: any = [];
  selectedThemeId: any = 1;
  totalTabs: any = "11";
  ThemeBasicColor: any;
  company_url: any;

  themeUrl: any = "";

  editCompany: any = {};

  viewAs: any = "product";
  isStore: boolean = false;

  selectedValue: string;

  prefixs: prefixs[] = [
    {value: 'Mr.', viewValue: 'Mr.'},
    {value: 'Mrs.', viewValue: 'Mrs.'},
    {value: 'Ms.', viewValue: 'Ms.'},
    {value: 'Dr.', viewValue: 'Dr.'},
    {value: 'CA.', viewValue: 'CA.'},
    {value: 'CS.', viewValue: 'CS.'},
    {value: 'Er.', viewValue: 'Er.'},
    {value: 'Adv.', viewValue: 'Adv.'},
    {value: '', viewValue: '-'},
  ];

  galleryTypes: any = ['Grid','Slider'];

  imageSrc: string;
  imageProductSrc: any = [];
  upiQCImg: any = [];
  galleryImage: any = [];
  editGalleryImage: any = [];

  config = {
    placeholder: '',
    insertText: '',
    tabsize: 2,
    minHeight: 100,
    dialogsInBody: true,
    toolbar: [
        ['misc', ['undo', 'redo']],
        ['insert', ['link', 'hr']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    ],
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  covertedData: any = [];
  company_response: any = [];

  inProgress: boolean = false;
  isMNCAllow: boolean = false;

  themeSelectedColor: any;
  themeSelectedImg: any;
  reverseColor: any = "";
  profileForm: any = new FormData();

  // Images Uploaded
  selectedProfileImage: File = null;
  selectedProductImage: any = [];
  selectedUPIImage: any = [];
  products_Form = new FormData();
  payment_Forms = new FormData();
  photo_gallery = new FormData();
  pdfBrochure = new FormData();
  totalRecords: number = 0;

  galleryPath: any;

  // Process for Edit
  editId: any;
  user_ID: any = 0;

  Records: any = [];
  pdfSrc: any = "";
  pdfBrochureArr: any = [];

  defaultBusiness: any = [];
  manualBusiness: any = [];
  defaultSpeciality: any = [];
  manualSpeciality: any = [];
  removable: boolean = true;
  userRole: any = "Client";

  //Delete Arrays
  delIds: any = [];
  delOtherIds: any = [];
  delPdf: any = [];
  delProducts: any = [];
  delOtherUPI: any = [];
  delYoutube: any = [];

  productBlob: any = [];
  paymentBlob: any = [];

  pdfCounter: number = 0;
  countries: any = [];
  flagUrl: any = "";
  selFlag: any = "in.png";
  Country_Name: any = "India";
  countrCode: any = "91";
  cardType: any = "dbc";

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private _router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    if(sessionStorage.getItem('userRole')=='Client'){
      this.user_ID = sessionStorage.getItem('userId');
    }
    if(sessionStorage.getItem("cardType")){
      this.cardType = sessionStorage.getItem("cardType");
    }
    this.galleryPath = this.http.serviceUrl+"photo-gallery/";
    this.imagePath = this.http.serviceUrl+"themes/";
    this.themeUrl = this.http.serviceUrl+"template-background/";

    this.flagUrl = this.http.serviceUrl+"flags/";

    this.userRole = sessionStorage.getItem('userRole');

    this.getThemes();
    this.getCountry();

    this.route.params.subscribe( 
      params => {
        this.editId = params.cardId;
        if(this.editId){
          this.getCompanyDetails(this.editId);
          console.log(this.editId);
        }
        if(params.userId){
          this.user_ID = params.userId;
        }
      }
    );
    if(sessionStorage.getItem('userRole')=='Client' && !this.editId){
      this.numberOfCards();
    }
    if(sessionStorage.getItem('mncCard')=='Allow' || sessionStorage.getItem('userRole')=='Super-Admin'){
      this.isMNCAllow = true;
    }
    console.log(sessionStorage.getItem('isStore'));
    if(sessionStorage.getItem('isStore')=='1'){
      this.isStore = true;
    }
    if(sessionStorage.getItem('tabNo')){
        this.tab = sessionStorage.getItem('tabNo');
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.themes = [
      // {
      //   'Theme_image': 'template1.png',
      //   'Theme_name' : 'basic Theme',
      //   'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
      // },
      // {
      //   'Theme_image': 'template2.png',
      //   'Theme_name' : 'basic Theme',
      //   'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
      // },
      // {
      //   'Theme_image': 'template3.png',
      //   'Theme_name' : 'basic Theme',
      //   'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
      // },
      // {
      //   'Theme_image': 'template4.png',
      //   'Theme_name' : 'basic Theme',
      //   'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
      // },
      // {
      //   'Theme_image': 'template5.png',
      //   'Theme_name' : 'basic Theme',
      //   'Available_colors': [{'color':'#efefef'},{'color':'#00ffff'},{'color':'#ff00ff'},{'color':'#ffff00'}]
      // }
    ];

   }

  numberOfCards(){
    let formdata = new FormData();
    formdata.append('user',sessionStorage.getItem('userRole'));
    formdata.append('userId',sessionStorage.getItem('userId'));
    formdata.append('franciseeId',sessionStorage.getItem('franciseeId'));
    this.http.postDataByForm('companies/get_card_listing',formdata)
        .then((data: any) => {
          console.log(data);
          if(data.status==1){
            //this.datasource = this.respo.data.records;
            let allCards = data.data.records;
            this.totalRecords = allCards.length;
            let cardCount = parseInt(sessionStorage.getItem('userCards'));
            if(this.totalRecords>=cardCount){
              let subPageName = sessionStorage.getItem('userRole')=='Client'?'':'card-listing';
              let pageName = '/'+sessionStorage.getItem('userRole').toLowerCase()+'/'+subPageName;
              this._router.navigate([pageName]);
            }
          }
        });
  }

  viewAsChange(viewType: any){
    this.viewAs = viewType;
    this.sevenFormGroup.get("layout").setValue("2");
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getCountry(){
    this.http.getDataByPage('users/get_country')
        .then((data:any) => {
          console.log(data);
          if(data.status==1){
              this.countries = data.data.records;
          }
        });
  }
  selCountryCode(){
    let countryCode = this.firstFormGroup.get('Countries').value;
    this.countries.forEach(c => {
        if(c.countries_iso_code==countryCode){
          this.selFlag = (c.countries_iso_code.toString().toLowerCase())+".png";
          this.firstFormGroup.get('Country_code').setValue(c.countries_isd_code);
          this.Country_Name = c.countries_name;
          this.countrCode = c.countries_isd_code;
        }
    });
  }

  getThemes(){
    this.inProgress = true;
    this.http.getDataByPage('companies/getThemeList')
             .then(data => {
                console.log(data);
                this.inProgress = false;
                this.Records = data;
                if(this.Records.status==1){
                  this.themes = this.Records.data.themesListing;
                  if(!this.themeSelectedColor){
                    this.themeSelectedColor = this.themes[0].Available_colors[0].color;
                  }
                  console.log(this.themeSelectedColor);
                }
            });
  }
  domreturn(url: any): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getCompanyDetails(companyId: any){
      this.inProgress = true;
      this.http.getDataByPage('companies/getCompany/'+companyId)
             .then(data => {
                console.log(data);
                this.Records = data;
                this.Records = this.Records.records;
                this.inProgress = false;
                // Form Company details
                this.previewURL = this.Records.company.previewURL;
                this.editCompany.Id = this.Records.company.Id;
                sessionStorage.setItem('companyId',this.Records.company.Id);
                this.firstFormGroup.get('Company_Url').setValue(this.Records.company.Company_Url);
                this.company_url = this.Records.company.Company_Url;
                this.firstFormGroup.get('Countries').setValue(this.Records.company.countries_iso_code);
                this.selFlag = this.selFlag = this.Records.company.countries_iso_code!=''?(this.Records.company.countries_iso_code.toString().toLowerCase())+".png":"in.png";
                this.firstFormGroup.get('Country_code').setValue(this.Records.company.Country_code);
                this.Country_Name = this.Records.company.Country_Name;
                this.firstFormGroup.get('Mobile_No').setValue(this.Records.company.Mobile_No);
                this.firstFormGroup.get('Company_Name').setValue(this.Records.company.Company_Name);
                if(this.Records.company.Company_Logo!=''){
                  this.imageSrc = this.http.serviceUrl+"/company-profile/"+this.Records.company.Company_Logo;
                }
                this.pdfSrc = this.http.serviceUrl+"/company-profile/";
                if(this.Records.company.pdfBrochuses!=''){
                  this.pdfBrochureArr = this.Records.company.pdfBrochuses.split(",");
                  this.pdfCounter = this.pdfBrochureArr.length;
                }
                /** ============================================================================= */
                let Themes = this.Records.themesData;
                if(Themes){
                  this.selectedThemeId = Themes.Theme_Template;
                  this.themeSelectedColor = Themes.Theme_Color;
                  this.editCompany.themeId = Themes.Id;
                }
                /** ============================================================================= */
                // console.log(this.Records.contacts);
                if(this.Records.profiles){
                  this.thirdFormGroup.setControl('userProfile',this.setExistingProfile(this.Records.profiles));
                }
                // Company Contacts
                this.editCompany.contactId = this.Records.contacts.Id;
                let RowItems = ["Contact_no","Alternet_Contact","Whatsapp_No","Company_Address"];
                this.thirdFormGroup.get(RowItems[0]).setValue(this.Records.contacts.Contact_no);
                this.thirdFormGroup.get(RowItems[1]).setValue(this.Records.contacts.Alternet_Contact);
                this.thirdFormGroup.get(RowItems[2]).setValue(this.Records.contacts.Whatsapp_No);
                this.thirdFormGroup.get(RowItems[3]).setValue(this.Records.contacts.Company_Address);
                /** ============================================================================= */
                this.fouthFormGroup.patchValue({
                  Email_Id:this.Records.company.Email_Id,
                  Website: this.Records.company.Website,
                  LocationMap: this.Records.company.LocationMap,
                });
                /** ============================================================================= */
                this.fifthFormGroup.patchValue({
                  Facebook: this.Records.company_social_media.Facebook,
                  Instagram: this.Records.company_social_media.Instagram,
                  Twitter: this.Records.company_social_media.Twitter,
                  Linkedin: this.Records.company_social_media.Linkedin,
                  Youtube: this.Records.company_social_media.Youtube,
                  Pintrest: this.Records.company_social_media.Pintrest
                });
                this.editCompany.socialId = this.Records.company_social_media.Id;
                if(this.Records.otherlink){
                  // console.log(this.Records.otherlink);
                  this.fifthFormGroup.setControl('otherlinks', this.setOtherLinks(this.Records.otherlink));
                }
                /** ============================================================================= */
                this.defaultBusiness = this.Records.company.Nature_of_business!=''?this.Records.company.Nature_of_business.split(','):[];
                this.manualBusiness = this.Records.company.Manual_Business!==''?JSON.parse(this.Records.company.Manual_Business):[];
                this.defaultSpeciality = this.Records.company.Specialties!=''?this.Records.company.Specialties.split(','):[];
                this.manualSpeciality = this.Records.company.Manual_Specialties!=''?JSON.parse(this.Records.company.Manual_Specialties):[];
                this.sixFormGroup.patchValue({
                  Establishment_Year: this.Records.company.Establishment_Year,
                  Nature_of_business: this.Records.company.Nature_of_business,
                  Manual_Business: this.Records.company.Manual_Business,
                  Specialties: this.Records.company.Specialties,
                  Manual_Specialties: this.Records.company.Manual_Specialties,
                  About_Us: this.Records.company.About_Us,
                });
                /** ============================================================================= */
                if(this.Records.company_products){
                  this.sevenFormGroup.setControl('productlists', this.setProducts(this.Records.company_products));
                }
                /** ============================================================================= */
                this.eightFormGroup.patchValue({
                  Bank_Name: this.Records.bankdetails.Bank_Name,
                  Account_Holder:this.Records.bankdetails.Account_Holder,
                  Account_No: this.Records.bankdetails.Account_No,
                  Account_Type: this.Records.bankdetails.Account_Type,
                  IFSC_Code: this.Records.bankdetails.IFSC_Code,
                  PAN_no: this.Records.bankdetails.PAN_no,
                  GST_no: this.Records.bankdetails.GST_no
                });
                this.editCompany.bankId = this.Records.bankdetails.Id;
                if(this.Records.payments){
                  this.eightFormGroup.setControl('upilists',this.editPayments(this.Records.payments));
                }
                /** ============================================================================= */
                if(this.Records.photo_gallery){
                    if(this.Records.photo_gallery.length>0){
                      this.Records.photo_gallery.forEach(photo => {
                          this.editGalleryImage.push(photo);
                      });
                    }
                }
                this.nineFormGroup.get('galleryType').setValue(this.Records.gallery.galleryType);
                this.nineFormGroup.get('Id').setValue(this.Records.gallery.Id);
                /** ============================================================================= */
                if(this.Records.company_youtube){
                  this.tenFormGroup.setControl('youtubes',this.editVideos(this.Records.company_youtube));
                }
                /** ============================================================================= */
                if(this.Records.visibility_settings){
                  this.elevenFormGroup.patchValue({
                    is_Aboutus: this.Records.visibility_settings.is_Aboutus,
                    is_Products: this.Records.visibility_settings.is_Products,
                    is_Payment: this.Records.visibility_settings.is_Payment,
                    is_Gallery: this.Records.visibility_settings.is_Gallery,
                    is_Youtube: this.Records.visibility_settings.is_Youtube,
                    is_Feedback: this.Records.visibility_settings.is_Feedback,
                    is_Enquiry: this.Records.visibility_settings.is_Enquiry,
                    is_Unentered_Social: this.Records.visibility_settings.is_Unentered_Social,
                  });
                  this.editCompany.hiddenId = this.Records.visibility_settings.Id;
                }
                /** ============================================================================= */
                if(this.Records.customise_headers){
                  this.customise_headers.patchValue({
                      About_Us_Section: this.Records.customise_headers.About_Us_Section,
                      Products_Section: this.Records.customise_headers.Products_Section,
                      Payment_Section: this.Records.customise_headers.Payment_Section,
                      Gallery_Section: this.Records.customise_headers.Gallery_Section,
                      Youtube_Section: this.Records.customise_headers.Youtube_Section,
                      Feedback_Section: this.Records.customise_headers.Feedback_Section,
                      Enquiry_Section: this.Records.customise_headers.Enquiry_Section,
                      Company_Subheading: this.Records.customise_headers.Company_Subheading,
                  });
                  this.editCompany.sectionId = this.Records.customise_headers.Id;
                }
                /** ============================================================================= */
                if(this.Records.companyseo){
                  this.companyseo.patchValue({
                    page_title: this.Records.companyseo.page_title,
                    page_description: this.Records.companyseo.page_description,
                    page_keywords: this.Records.companyseo.page_keywords,
                    page_google_code: this.Records.companyseo.page_google_code,
                    Id: this.Records.companyseo.Id
                  });
                }

                this.countrCode = this.Records.company.Country_code;
             });
  }

  getKeyFromArr(arrRecords: any): any{
      return Object.keys(arrRecords);
  }

  // Functions for Edit
  setExistingProfile(profiles: any[]) : FormArray{
      const formArr = new FormArray([]);
      profiles.forEach(p => {
        formArr.push(this._formBuilder.group({
            Id: p.Id,
            Prefix: p.Prefix,
            First_Name: p.First_Name,
            Last_Name: p.Last_Name,
            Designation: p.Designation,
            Other_details: p.Other_details,
          }));
      });
      return formArr;
  }
  setProducts(products: any[]) : FormArray{
    const formArr = new FormArray([]);
    let positions = 0;
    products.forEach(p => {
        formArr.push(this._formBuilder.group({
          Id: p.Id,
          Product_Name: p.Product_Name,
          Details: p.Details,
          Product_Price: p.Product_Price,
          Product_Discount: p.Product_Discount,
          Product_Qty: p.Product_Qty,
          Hide_enquiry: p.Hide_enquiry==1?"1":"",
          productImage: p.ProImages,
          isProImage: ''
        }));
        if(p.ProImages!=''){
          let proImage = this.http.serviceUrl+"products/"+p.ProImages;
          let imageDetails = {'result':proImage,'productId':positions};
          this.imageProductSrc[positions] = imageDetails;
        }
        positions++;
      });
    console.log(this.imageProductSrc);
    return formArr;
  }
  editPayments(payments: any[]) : FormArray{
    const formArr = new FormArray([]);
    let positions = 0;
    payments.forEach(p => {
        formArr.push(this._formBuilder.group({
          Id: p.Id,
          Payment_Name: p.Payment_Name,
          Payment_contactNo: p.Payment_contactNo,
          upiQCcode: p.Payment_QCcode,
          isQCImage: ''
        }));
        if(p.Payment_QCcode!=''){
          let qcImage = this.http.serviceUrl+"payment-options/"+p.Payment_QCcode;
          let imageDetails = {'result':qcImage,'upiId':positions};
          this.upiQCImg[positions] = imageDetails;
        }
        positions++;
      });
    return formArr;
  }
  editVideos(videos: any[]) : FormArray{
    const formArr = new FormArray([]);
    videos.forEach(v => {
        formArr.push(this._formBuilder.group({
          Id: v.Id,
          videourl: v.Youtube_Links
        }));
      });
    return formArr;
  }
  setOtherLinks(otherlink: any[]): FormArray{
    const formArr = new FormArray([]);
    otherlink.forEach(o => {
        formArr.push(this._formBuilder.group({
          Id: o.Id,
          otherlink: o.Other_Links
        }));
      });
    return formArr;
  }

  invertHex(hex: any) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.selectedProfileImage = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  onProductImgChange(event: any, positions: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.selectedProductImage[positions] = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        let imageDetails = {'result':reader.result,'productId':positions};
        this.imageProductSrc[positions] = imageDetails;
        this.productLists().at(positions).get('isProImage').setValue('imgAvail');
        console.log(this.imageProductSrc);
        let blob = this.convertBase64ToBlob(reader.result as string);
        // Append in blob
        this.productBlob[positions] = blob;
      };
    }
  }

  onProductPdfChange(event: any, positions: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        console.log(event.target.files[0].type);
        // this.selectedProductImage[positions] = <File>event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          // let imageDetails = {'result':reader.result,'productId':positions};
          let pdfName = positions+".pdf";
          // this.imageProductSrc[positions] = imageDetails;
          // console.log(this.imageProductSrc);
          let blob = this.convertBase64ToBlob(reader.result as string);
          this.products_Form.append('productPdfs[]',blob,pdfName);
        };
    }
  }

// BLOB conversation
private convertBase64ToBlob(base64: string) {
  const info = this.getInfoFromBase64(base64);
  const sliceSize = 512;
  const byteCharacters = window.atob(info.rawBase64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: info.mime });
}
private getInfoFromBase64(base64: string) {
  const meta = base64.split(',')[0];
  const rawBase64 = base64.split(',')[1].replace(/\s/g, '');
  const mime = /:([^;]+);/.exec(meta)[1];
  const extension = /\/([^;]+);/.exec(meta)[1];
  return {
    mime,
    extension,
    meta,
    rawBase64
  };
}
// End of BLOB Conversation

  onQCImgChange(event: any, positions: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.selectedUPIImage[positions] = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        let imageDetails = {'result':reader.result,'upiId':positions};
        let imageName = positions+".png";
        this.otherUPIs().at(positions).get('isQCImage').setValue('imgAvail');
        this.upiQCImg[positions] = imageDetails;
        let blob = this.convertBase64ToBlob(reader.result as string);
        this.paymentBlob[positions] = blob;
      };
    }
  }

  onImgGalleryChange(event: any){
    this.galleryImage = [];
    if(event.target.files && event.target.files.length) {
      for(var i=0;i<event.target.files.length;i++){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (event: any) => {
          let imageName = i+".png";
          let blob = this.convertBase64ToBlob(event.target.result as string);
          this.photo_gallery.append('gallery[]',blob,imageName);
          this.galleryImage.push(event.target.result as string);
        };
      }
    }
  }

  selectPdf(event: any){
    this.pdfCounter = this.pdfCounter + event.target.files.length;
    if(this.pdfCounter>2){
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: 'Maximum 2 brochures !', message: "You can select maximum 2 brochures.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {         
      });
    }
    else {
      if(event.target.files && event.target.files.length) {
        for(var i=0;i<event.target.files.length;i++){
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = (event: any) => {
            let imageName = i+".pdf";
            let blob = this.convertBase64ToBlob(event.target.result as string);
            this.pdfBrochure.append('brochure[]',blob,imageName);
          };
        }
      }
    }
  }

  selectTheme(i: any) {
      console.log(this.themes[i]);
      this.selectedThemeId = this.themes[i].Id;
      if(!this.themeSelectedImg){
        if(this.themes[i].theme_bg.length >0) {
          this.themeSelectedImg = this.themes[i].theme_bg[0].background;
        }
      }
  }
  changeColor(){
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
    });
    dialogRef.afterClosed().subscribe(result => {
        
    });
  }
  convertStr2Url() {
    var str = this.firstFormGroup.get('Company_Name').value;
    str = str.replace(/\s+/g, '-');
    str = str.toLowerCase();    
    console.log(str);
    //this.firstFormGroup.get('Company_Url').setValue(str);
    let formdata = new FormData();
    formdata.append('company_name',str);
    if(this.editCompany.Id){
      formdata.append('Id', this.editCompany.Id);
    }
    this.inProgress = true;
    this.http.postDataByForm('companies/createslug',formdata)
             .then(data => {
                console.log(data);
                this.covertedData = data;
                this.inProgress = false;
                this.firstFormGroup.get('Company_Url').setValue(this.covertedData.page_url);
                this.company_url = this.covertedData.page_url;
                this.previewURL = this.covertedData.previewURL;
             });
  }
  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      Countries: 'IN',
      Country_code: ['91', Validators.required], 
      Mobile_No: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,20}')
      ])],
      Company_Name: ['', Validators.required],
      Company_Url: ['', Validators.required]
    });
    if(sessionStorage.getItem('userRole')=='Client'){
      let user = JSON.parse(sessionStorage.getItem('user'));
      this.firstFormGroup.get('Mobile_No').setValue(user.data.Contact_No);
    }
    this.thirdFormGroup = this._formBuilder.group({
      file: ['', Validators.required],
      userProfile: this._formBuilder.array([]),
      Contact_no: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,20}')
      ])],
      Alternet_Contact: ['', Validators.compose([
        Validators.pattern('^[0-9]{10,20}')
      ])],
      Whatsapp_No: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,20}')
      ])],
      Company_Address: ['', Validators.required],
    });

    this.fouthFormGroup = this._formBuilder.group({
      Email_Id:['', Validators.compose([
        Validators.pattern('^[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$')
      ])],
      Website: '',
      LocationMap: '',
    });

    this.fifthFormGroup = this._formBuilder.group({
      Facebook: '',
      Instagram: '',
      Twitter: '',
      Linkedin: '',
      Youtube: '',
      Pintrest: '',
      otherlinks: this._formBuilder.array([]),
    });

    this.sixFormGroup = this._formBuilder.group({
      Establishment_Year: ['', Validators.required],
      Nature_of_business: '',
      Manual_Business: '',
      Specialties: '',
      Manual_Specialties: '',
      About_Us: '',
    });

    this.sevenFormGroup = this._formBuilder.group({
      viewAs: ['product', Validators.required],
      layout: ['1', Validators.required],
      productlists: this._formBuilder.array([]),
    });

    this.eightFormGroup = this._formBuilder.group({
      upilists: this._formBuilder.array([]),
      Bank_Name: '',
      Account_Holder:'',
      Account_No: '',
      Account_Type: '',
      IFSC_Code: '',
      PAN_no: '',
      GST_no: ''
    });
    let upiCards = [
      {
        'Payment_Name': 'Paytm',
        'Payment_contactNo': '',
        'Payment_QCcode':''
      },
      {
        'Payment_Name': 'Google Pay',
        'Payment_contactNo': '',
        'Payment_QCcode':''
      },
      {
        'Payment_Name': 'Phonepe',
        'Payment_contactNo': '',
        'Payment_QCcode':''
      },
    ]
    this.eightFormGroup.setControl('upilists',this.editPayments(upiCards));

    this.nineFormGroup = this._formBuilder.group({
      Id: '',
      imggallery: '',
      galleryType: 'Grid'
    });

    this.tenFormGroup = this._formBuilder.group({
      youtubes: this._formBuilder.array([]) ,
    });

    this.elevenFormGroup = this._formBuilder.group({
      is_Aboutus: '',
      is_Products: '',
      is_Payment:'',
      is_Gallery:'',
      is_Youtube:'',
      is_Feedback:'',
      is_Enquiry:'',
      is_Unentered_Social:''
    });

    this.customise_headers = this._formBuilder.group({
        About_Us_Section: 'About Us',
        Products_Section: 'Products',
        Payment_Section:'Payment',
        Gallery_Section:'Gallery',
        Youtube_Section:'Video',
        Feedback_Section:'Feedback',
        Enquiry_Section:'Enquiry',
        Company_Subheading:'Company Name'
    });

    this.companyseo = this._formBuilder.group({
      Id: '',
      page_title: '',
      page_description: '',
      page_keywords:'',
      page_google_code:''
  });
    this.addProfileUser();
  }

  goBackStep(){
      this.tab--;
      sessionStorage.setItem('tabNo',this.tab);
  }
  // Create Company
  createCompany(){
    let formdata = new FormData();
    let firstForm = this.firstFormGroup.value;
    firstForm.Country_Name = this.Country_Name;
    if(this.editCompany.Id){
      firstForm.Id = this.editCompany.Id;
      firstForm.Updated_by = sessionStorage.getItem('userId');
    }
    else {
      firstForm.Created_by = sessionStorage.getItem('userId');
    }
    if(sessionStorage.getItem('userRole')=='Client'){
      let user = JSON.parse(sessionStorage.getItem('user'));
      firstForm.Mobile_No = user.data.Contact_No;
      firstForm.User_Id = sessionStorage.getItem('userId');
      firstForm.Created_by = sessionStorage.getItem('franciseeId');
    }
    else {
      firstForm.company_name = sessionStorage.getItem('company_name');
      firstForm.Francisee_Id = sessionStorage.getItem('franciseeId');
    }
    if(this.user_ID!=0){
      firstForm.User_Id = this.user_ID;
    }
    let data = JSON.stringify(firstForm);
    this.inProgress = true;
    formdata.append('company',data);
    this.http.postDataByForm('companies/createcompany',formdata)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              if(this.company_response.status==1){
                sessionStorage.setItem('companyId',this.company_response.data.companyId);
                this.tab = 2;
                sessionStorage.setItem('tabNo',"2");
              }
              else {
                  
              }
            });
  }
  backToCardlist(){
    sessionStorage.removeItem('tabNo');
    let subPageName = sessionStorage.getItem('userRole')=='Client'?'':'card-listing';
    let pageName = '/'+sessionStorage.getItem('userRole').toLowerCase()+'/'+subPageName;
    this._router.navigate([pageName]);
  }
  saveStepTwo(){
    let companyId = sessionStorage.getItem('companyId');
    let formdata = new FormData();
    let themedata = {
      Company_Id : companyId,
      Theme_Template: this.selectedThemeId,
      Theme_Color: this.themeSelectedColor,
      Id: ''
    };
    if(this.editCompany.themeId){
      themedata.Id = this.editCompany.themeId;
    }
    let data = JSON.stringify(themedata);
    this.inProgress = true;
    formdata.append('themes',data);
    this.http.postDataByForm('companies/save_themes',formdata)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 3;
                sessionStorage.setItem('tabNo',"3");
              }
              else {
                  
              }
            });
  }

  saveStepThree(){
    let companyId = sessionStorage.getItem('companyId');
    let profileData = this.thirdFormGroup.value;
    profileData.Company_Id = companyId;
    if(this.editCompany.contactId){
      profileData.contactId = this.editCompany.contactId;
    }
    if(this.userRole=='Franchisee'){
      profileData.Francisee_Id = sessionStorage.getItem('franciseeId');
      profileData.company_name = sessionStorage.getItem('company_name');
    }
    let data = JSON.stringify(profileData);
    console.log(data);
    this.profileForm.append('delIds',JSON.stringify(this.delIds));
    this.inProgress = true;
    this.profileForm.append('profile',data);
    if(this.selectedProfileImage){
      this.profileForm.append('profileImage', this.selectedProfileImage, this.selectedProfileImage.name);
    }
    this.http.postDataByForm('companies/company_profile',this.profileForm)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 4;
                sessionStorage.setItem('tabNo',"4");
              }
              else {
                  
              }
            });
  }
  profileUser() : FormArray {
    return this.thirdFormGroup.get("userProfile") as FormArray
  }
  newprofileUser(): FormGroup {
    return this._formBuilder.group({
      Id: '',
      Prefix: '',
      First_Name: ['', Validators.required],
      Last_Name: ['', Validators.required],
      Designation: '',
      Other_details: ''
    })
  }

  addProfileUser() {
    this.profileUser().push(this.newprofileUser());
  }   
  removeProfileUser(i:number) {
    let delId = this.profileUser().at(i).get('Id').value;
    this.delIds.push(delId);
    this.profileUser().removeAt(i);
  }
  saveStepFour(){
    let companyId = sessionStorage.getItem('companyId');
    let linkData = this.fouthFormGroup.value;
    linkData.Id = companyId;
    let data = JSON.stringify(linkData);
    this.inProgress = true;
    let linkform = new FormData();
    linkform.append('links',data);
    this.http.postDataByForm('companies/company_links',linkform)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 5;
                sessionStorage.setItem('tabNo',"5");
              }
              else {
                  
              }
            });

  }      

  otherLinks() : FormArray {
    return this.fifthFormGroup.get("otherlinks") as FormArray
  }
  newLinks(): FormGroup {
    return this._formBuilder.group({
      Id: '',
      otherlink: ''
    })
  }
  addMoreLinks() {
    this.otherLinks().push(this.newLinks());
  }   
  removeLink(i:number) {
    let delId = this.otherLinks().at(i).get('Id').value;
    this.delOtherIds.push(delId);
    this.otherLinks().removeAt(i);
  }
  saveStepFive(){
    let companyId = sessionStorage.getItem('companyId');
    let socialMedia = this.fifthFormGroup.value;
    socialMedia.Company_Id = companyId;
    if(this.editCompany.socialId){
        socialMedia.Id = this.editCompany.socialId;
    }
    let data = JSON.stringify(socialMedia);
    this.inProgress = true;
    let socialForm = new FormData();
    socialForm.append('sociallinks',data);
    socialForm.append('delOtherIds',JSON.stringify(this.delOtherIds));
    this.http.postDataByForm('companies/company_socialmedia',socialForm)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 6;
                sessionStorage.setItem('tabNo',"6");
              }
              else {
                  
              }
            });
  }
  saveStepSix(){
    let companyId = sessionStorage.getItem('companyId');
    let about_us = this.sixFormGroup.value;
    about_us.Id = companyId;
    let data = JSON.stringify(about_us);
    this.inProgress = true;
    this.pdfBrochure.append('about_us',data);
    this.pdfBrochure.append('pdfBrochuses',JSON.stringify(this.pdfBrochureArr));
    this.http.postDataByForm('companies/about_us',this.pdfBrochure)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.pdfBrochure = new FormData();
                this.pdfBrochureArr = this.company_response.data.records;
                this.tab = 7;
                sessionStorage.setItem('tabNo',"7");
              }
              else {
                  
              }
            });
  }
  removePDF(i: any){
    this.pdfCounter--;
    this.pdfBrochureArr.splice(i,1);
  }
  popupNature(){
    let dialogRef = this.dialog.open(BusinessComponent, {
      width: "450px",
      data: { default: JSON.stringify(this.defaultBusiness), manual: JSON.stringify(this.manualBusiness)}, 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result){
            let lastResult = JSON.parse(result);
            this.defaultBusiness = JSON.parse(lastResult['business']);
            let nature_of_business="";
            if(this.defaultBusiness!='')
            {
              this.defaultBusiness.forEach(element => {
                nature_of_business = nature_of_business+element+",";
              });
              nature_of_business = nature_of_business.slice(0, -1);
            }            
            this.sixFormGroup.get('Nature_of_business').setValue(nature_of_business.toString());
            this.sixFormGroup.get('Manual_Business').setValue(lastResult['manual']);
            this.manualBusiness = JSON.parse(lastResult['manual']);
        }
    });
  }
  removeBusiness(type:any, i: any){
    if(type=='default'){
      this.defaultBusiness.splice(i,1);
      let nature_of_business="";
      if(this.defaultBusiness!='')
      {
        this.defaultBusiness.forEach(element => {
          nature_of_business = nature_of_business+element+",";
        });
        nature_of_business = nature_of_business.slice(0, -1);
      }            
      this.sixFormGroup.get('Nature_of_business').setValue(nature_of_business.toString());
    }
    else {
      this.manualBusiness.splice(i,1);
      this.sixFormGroup.get('Manual_Business').setValue(JSON.stringify(this.manualBusiness));
    }
  }
  popupSpeciality(){
    let dialogRef = this.dialog.open(SpecialityComponent, {
      width: "450px",
      data: { default: JSON.stringify(this.defaultSpeciality), manual: JSON.stringify(this.manualSpeciality)}, 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result){
            let lastResult = JSON.parse(result);            
            this.defaultSpeciality = JSON.parse(lastResult['speciality']);
            let speciality = '';
            if(this.defaultSpeciality!='')
            {
              this.defaultSpeciality.forEach(element => {
                speciality = speciality+element+",";
              });
              speciality = speciality.slice(0, -1);
            }
            this.sixFormGroup.get('Specialties').setValue(speciality.toString());
            this.sixFormGroup.get('Manual_Specialties').setValue(lastResult['manual']);
            this.manualSpeciality = JSON.parse(lastResult['manual']);
        }
    });
  }
  removeSpeciality(type:any, i: any){
    if(type=='default'){
      let speciality = '';
      if(this.defaultSpeciality!='')
      {
        this.defaultSpeciality.forEach(element => {
          speciality = speciality+element+",";
        });
        speciality = speciality.slice(0, -1);
      }
      this.sixFormGroup.get('Specialties').setValue(speciality.toString());
      this.defaultSpeciality.splice(i,1);
    }
    else {
      this.manualSpeciality.splice(i,1);
      this.sixFormGroup.get('Manual_Specialties').setValue(JSON.stringify(this.manualSpeciality));
    }
  }
  productLists() : FormArray {
    return this.sevenFormGroup.get("productlists") as FormArray
  }
  newProducts(): FormGroup {
    return this._formBuilder.group({
      Id: '',
      Product_Name: '',
      Details: '',
      Product_Price: '',
      Product_Discount: '',
      Product_Qty: '',
      Hide_enquiry: '',
      productImage: '',
      isProImage: ''
      // pdfUrl: '',
      // product_video: '',
      // is_Sharable: ''
    })
  }
  addMoreProducts() {
    this.productLists().push(this.newProducts());
  }   
  removeProduct(i: number) {
    this.delProducts.push(this.productLists().at(i).get('Id').value);
    for(let j=0;j<(this.imageProductSrc.length-1);j++){
        if(j>=i){
          if(!this.imageProductSrc[j]){
            this.imageProductSrc[j] = {'productId':j,'result':''}; 
          }
          if(!this.imageProductSrc[j+1]){
            this.imageProductSrc[j+1] = {'productId':j+1,'result':''}; 
          }
          this.imageProductSrc[j].productId = (this.imageProductSrc[j+1].productId-1);
          this.imageProductSrc[j].result = this.imageProductSrc[j+1].result;
        }
    }
    this.productLists().at(i).get('isProImage').setValue('');
    let count = this.imageProductSrc.length - 1;
    if(this.imageProductSrc[count]){
      this.imageProductSrc.splice(count,1);
    }
    this.productLists().removeAt(i);
  }
  removProduct(i: number){
    this.imageProductSrc[i]['result'] = '';
    this.productLists().at(i).get('productImage').setValue('');
    this.productLists().at(i).get('isProImage').setValue('');
  }
  saveStepSeven(){
    if(this.productBlob.length>0){
        let number = 0;
        let imageName = "";
        this.productBlob.forEach(element => {
          if(element)
          {
            imageName = "product-"+number+".png";
            this.products_Form.append('productImgs[]',element,imageName);
          }
        });
    }
    let companyId = sessionStorage.getItem('companyId');
    let products = this.sevenFormGroup.value;
    console.log(products);
    products.Company_Id = companyId;
    let data = JSON.stringify(products);
    this.inProgress = true;
    //let products_Form = new FormData();
    this.products_Form.append('products',data);
    this.products_Form.append('delProducts',JSON.stringify(this.delProducts));
    //this.products_Form.append('productsImgs[]',this.selectedProfileImage,'productsname.jpg');
    this.http.postDataByForm('companies/add_products',this.products_Form)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.products_Form = new FormData();
                this.imageProductSrc = [];
                this.selectedProductImage = [];
                this.productBlob = [];
                this.productLists().clear();
                if(this.company_response.data.product){
                  this.sevenFormGroup.setControl('productlists', this.setProducts(this.company_response.data.product));
                }
                this.tab = 8;
                sessionStorage.setItem('tabNo',"8");
              }
              else {
                  
              }
            });
  }  
  
  otherUPIs() : FormArray {
    return this.eightFormGroup.get("upilists") as FormArray
  }
  newUPIs(): FormGroup {
    return this._formBuilder.group({
      Id: '',
      Payment_Name: '',
      Payment_contactNo: '',
      upiQCcode: '',
      isQCImage: ''
    })
  }
  addNewUPI() {
    this.otherUPIs().push(this.newUPIs());
  }
  removeUPI(i: number) {
    if(this.otherUPIs().at(i).get('Id').value){
      this.delOtherUPI.push(this.otherUPIs().at(i).get('Id').value);
    }
    for(let j=0;j<(this.upiQCImg.length-1);j++){
        if(j>=i){
          if(!this.upiQCImg[j]){
            this.upiQCImg[j] = {'upiId':j,'result':''}; 
          }
          if(!this.imageProductSrc[j+1]){
            this.upiQCImg[j+1] = {'upiId':j+1,'result':''};
          }
          this.upiQCImg[j].upiId = (this.upiQCImg[j+1].upiId-1);
          this.upiQCImg[j].result = this.upiQCImg[j+1].result;
        }
    }
    let count = this.upiQCImg.length-1;
    this.upiQCImg.splice(count,1);
    this.otherUPIs().removeAt(i);
  }
  removUPI(i: number){
    this.upiQCImg[i]['result'] = '';
    this.otherUPIs().at(i).get('upiQCcode').setValue('');
  }
  saveStepEight(){
    let companyId = sessionStorage.getItem('companyId');
    let paymentOptions = this.eightFormGroup.value;
    paymentOptions.Company_Id = companyId;
    if(this.editCompany.bankId){
      paymentOptions.Id = this.editCompany.bankId;
    }
    let data = JSON.stringify(paymentOptions);
    this.inProgress = true;
    // let products_Form = new FormData();
    if(this.paymentBlob.length>0){
      let number = 0;
      let imageName = "";
      this.paymentBlob.forEach(element => {
        if(element)
        {
          imageName = "payment-"+number+".png";
          this.payment_Forms.append('upiImages[]',element,imageName);
        }
      });
  }
    this.payment_Forms.append('payment_options',data);
    this.payment_Forms.append('delOtherUPI',JSON.stringify(this.delOtherUPI));
    // this.products_Form.append('productsImgs[]',this.selectedProfileImage,'productsname.jpg');
    this.http.postDataByForm('companies/payment_options',this.payment_Forms)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.payment_Forms = new FormData();
                this.selectedUPIImage = [];
                this.upiQCImg = [];
                this.otherUPIs().clear();
                this.paymentBlob = [];
                if(this.company_response.data.upilists){
                  this.eightFormGroup.setControl('upilists', this.editPayments(this.company_response.data.upilists));
                }
                this.tab = 9;
                sessionStorage.setItem('tabNo',"9");
              }
              else {
                  
              }
            });
  }
  removeGallery(i:number){
    this.galleryImage.splice(i,1);
    this.photo_gallery = new FormData();
    for(let i=0;i<this.galleryImage.length;i++){
      let imageName = i+".png";
      let blob = this.convertBase64ToBlob(this.galleryImage[i]);
      this.photo_gallery.append('gallery[]',blob,imageName);
    }
  }
  removeEditGallery(i:number){
    this.editGalleryImage.splice(i,1);
  }
  saveStepNine(){
    let companyId = sessionStorage.getItem('companyId');
    let photogallery = this.nineFormGroup.value;
    photogallery.Company_Id = companyId;
    photogallery.galleryType = this.nineFormGroup.get('galleryType').value;
    let data = JSON.stringify(photogallery);
    this.inProgress = true;
    //let products_Form = new FormData();
    this.photo_gallery.append('photo_gallery',data);
    this.photo_gallery.append('Images',JSON.stringify(this.editGalleryImage));
    //this.products_Form.append('productsImgs[]',this.selectedProfileImage,'productsname.jpg');
    this.http.postDataByForm('companies/photo_gallery',this.photo_gallery)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 10;
                sessionStorage.setItem('tabNo',"10");
              }
              else {
                  
              }
            });
  }
  youtubeVideos() : FormArray {
    return this.tenFormGroup.get("youtubes") as FormArray
  }
  newVideos(): FormGroup {
    return this._formBuilder.group({
      Id: '',
      videourl: ''
    })
  }
  addNewVideo() {
    this.youtubeVideos().push(this.newVideos());
  }   
  removeVideo(i:number) {
    this.delYoutube.push(this.youtubeVideos().at(i).get('Id').value);
    this.youtubeVideos().removeAt(i);
  }
  // onSubmit() {
  //   console.log(this.tenFormGroup.value);
  // }
  saveStepTen(){
    let companyId = sessionStorage.getItem('companyId');
    let youtubes = this.tenFormGroup.value;
    youtubes.Company_Id = companyId;
    let data = JSON.stringify(youtubes);
    this.inProgress = true;
    let youtubeForm = new FormData();
    youtubeForm.append('youtube_videos',data);
    youtubeForm.append('delYoutube',JSON.stringify(this.delYoutube));
    this.http.postDataByForm('companies/youtube_videos',youtubeForm)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                this.tab = 11;
                sessionStorage.setItem('tabNo',"11");
              }
              else {
                  
              }
            });
  }
  saveFinal(){
    let companyId = sessionStorage.getItem('companyId');
    let visibility_settings = this.elevenFormGroup.value;
    visibility_settings.Company_Id = companyId;
    if(this.editCompany.hiddenId){
      visibility_settings.Id = this.editCompany.hiddenId;
    }
    let data = JSON.stringify(visibility_settings);

    let customise_headers = this.customise_headers.value;
    customise_headers.Company_Id = companyId;
    if(this.editCompany.sectionId){
      customise_headers.Id = this.editCompany.sectionId;
    }
    let customisedata = JSON.stringify(customise_headers);
    let companyseo = JSON.stringify(this.companyseo.value);
    this.inProgress = true;
    let settings = new FormData();
    settings.append('customise_headers',customisedata);
    settings.append('visibility_settings',data);
    settings.append("companyseo",companyseo);
    this.http.postDataByForm('companies/site_settings',settings)
            .then(data => {
              this.inProgress = false;
              this.company_response = data;
              console.log(this.company_response);
              if(this.company_response.status==1){
                sessionStorage.removeItem('tabNo');
                let pageName = '/'+sessionStorage.getItem('userRole').toLowerCase()+'/card-listing';
                if(!this.editId){
                  if(sessionStorage.getItem('userRole')=='Client'){
                    pageName = "/"+sessionStorage.getItem('userRole').toLowerCase()+"/subscription/"+companyId+"|"+this.company_url;
                  }
                }
                else {
                  if(sessionStorage.getItem('userRole')=='Client'){
                    pageName = "/"+sessionStorage.getItem('userRole').toLowerCase();
                  }
                }
                this._router.navigate([pageName]);
              }
            });
  }
  openPreviewDialog(): void {
    this._bottomSheet.open(BottomSheetOverviewExampleSheet,
        {
          panelClass: 'page-preview',
          data : { company_url: this.previewURL+this.company_url+"/"+this.selectedThemeId+"/"+this.themeSelectedColor.replace("#","") }
        }
    );
  }
  openColorPlat(themeColors: any): void {
    // let colors = ['Frodo', 'Bilbo'];
    let sheet = this._bottomSheet.open(ColorPlat,
      {
        ariaLabel: 'Select Theme Color',
        panelClass: 'page-plat-color',
        data: { colors: themeColors, activecolor: this.themeSelectedColor},  
    });
    sheet.afterDismissed().subscribe((str) => {
      if(str){
        this.themeSelectedColor = str;
        this.reverseColor = this.invertHex(str);
        console.log('Bottom sheet has been dismissed.'+this.reverseColor);
      }
    });
  }
  openBackImagePlat(theme_bg: any): void {
    // let colors = ['Frodo', 'Bilbo'];
    let sheet = this._bottomSheet.open(BackgroundPlat,
      {
        ariaLabel: 'Select Theme Backgound',
        panelClass: 'page-plat-color',
        data: { backgroundList: theme_bg, activeBgImage: this.themeSelectedImg, themeUrl: this.themeUrl },  
      }
    );
    sheet.afterDismissed().subscribe((str) => {
      if(str){
        this.themeSelectedImg = str;
      }
    });
  }
}

// Open Print Preview
@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  templateUrl: 'preview-page.html',
})
export class BottomSheetOverviewExampleSheet {
  company_url: any;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>,
    private sanitizer: DomSanitizer
  ) {
      this.company_url = data.company_url;
      console.log(data.company_url);
  }
  domreturn(url: any): SafeResourceUrl {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  dismissPopup()
  {
      this._bottomSheetRef.dismiss();
  }
}

// Open color plat
@Component({
  selector: 'color-plat',
  templateUrl: 'color-plat.html',
})
export class ColorPlat {
  themeLabel: any = "Select Theme Color";
  colorList: any = [];
  activeColor: any;

  colorFormGroup: FormGroup;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
    private _bottomSheetRef: MatBottomSheetRef<ColorPlat>,
    private _formBuilder: FormBuilder
  ) {
    console.log(data.colors);
    this.colorList = data.colors;
    this.activeColor = data.activecolor;
    console.log(this.activeColor);

    this.colorFormGroup = this._formBuilder.group({
      customcolor:'',
    });
  }

  customColor(){
    let color = this.colorFormGroup.value;
    this._bottomSheetRef.dismiss(color.customcolor);
    console.log(this.colorFormGroup.value);
  }

  selectColor(color: any): any {
    this._bottomSheetRef.dismiss(color);
  }

  dismissPopup()
  {
    this._bottomSheetRef.dismiss();
  }
}

// Open Background plat
@Component({
  selector: 'background-plat',
  templateUrl: 'background-plat.html',
})
export class BackgroundPlat {
  themeLabel: any = "Select Theme Backgound";
  backgroundList: any = [];
  activeBgImage: any;
  themeUrl: any;  

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any,
    private _bottomSheetRef: MatBottomSheetRef<BackgroundPlat>
  ) {
    console.log(data.backgroundList);
    this.backgroundList = data.backgroundList;
    this.activeBgImage = data.activeBgImage;
    this.themeUrl = data.themeUrl;
    console.log(this.activeBgImage);
  }

  selectBgImage(background: any): any {
    this._bottomSheetRef.dismiss(background);
  }

  dismissPopup()
  {
    this._bottomSheetRef.dismiss();
  }
}