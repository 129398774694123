<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]='!inProgress'>
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>
<!--  -->
<div *ngIf="!isRegister" class="main-container" [style.background-image]="'url('+background+')'">
    <div class="row" style="margin-right: 0px; margin-left: 0px; height: 100vh;">
        <div class="col-md-5 my-auto" style="    margin-left: auto;
        margin-right: auto;
        background: #ffffffb3;
        max-width: 500px;
        padding: 50px;
        border-radius: 20px;
        border: 10px solid #47505975">
        <div>
            <div style="text-align: center; margin-bottom:20px">
                <img style="margin: auto;" *ngIf="Franchisee_logo" [src]="Franchisee_logo" alt="{{company_name}}">
            </div>
            <form [formGroup]="firstFormGroup">
                <div style="text-align: center; margin-bottom: 10px;">
                    <img src="../../../assets/images/logo-removebg-preview.png" alt="MyDigicard" />
                </div>
                <h2 style="text-align: center;">User Login</h2>
                <div class="form-group">
                    <input class="form-control" id="contact_no" oninput="this.value=this.value.replace(/[^0-9]/g,'');" fxFlex="100" placeholder="Contact No" formControlName="contact_no" required>
                </div>
                <div [hidden]="invalidUser">
                    <div class="form-group" style="position: relative;">
                        <input class="form-control" type="{{passwordType}}" fxFlex="100" placeholder="Password" formControlName="password" required>
                        <mat-icon (click)="changeType()" *ngIf="passwordType=='password'" style="position: absolute;
                        z-index: 9999;
                        right: 10px;
                        top: 10px;">visibility</mat-icon>
                        <mat-icon (click)="changeType()" *ngIf="passwordType!=='password'" style="position: absolute;
                        z-index: 9999;
                        right: 10px;
                        top: 10px;">visibility_off</mat-icon>
                    </div>
                    <mat-label class="forgetcss"><button button type="button" mat-button color="primary">Forgot password ?</button></mat-label>
                </div>
                <div class="text-center" style="margin-top: 10px;"></div>
                <!-- <mat-divider></mat-divider> -->
                <div class="text-center" style="padding-top: 15px;" *ngIf="invalidUser">
                    <!-- <button (click)="registerNow('register')" color="primary" mat-raised-button style="float: left; margin-top: 10px;">Create New Card</button> -->
                    <!-- <button mat-fab color="primary" (click)="checkValidUser()">
                        <mat-icon>login</mat-icon>
                    </button> -->
                    <button type="button" mat-raised-button (click)="checkValidUser()" color="primary">Check credential <mat-icon>login</mat-icon></button>
                    <div style="clear: both;"></div>
                </div>
                <div class="text-center" style="padding-top: 15px;" *ngIf="!invalidUser">
                    <button type="button" mat-raised-button (click)="loginNow()" [disabled]="!firstFormGroup.valid" color="primary">Login <mat-icon>login</mat-icon></button> 
                    <!-- <button (click)="registerNow('register')" color="primary" mat-raised-button  style="float: left;">Create New Card</button> -->
                    <div style="clear: both;"></div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
<div *ngIf="isRegister" class="main-container">
    <div class="row" style="margin-right: 0px; margin-left: 0px; height: 100vh;">
        <div class="col-md-5 my-auto" style="margin-left: auto; margin-right: auto;">
        <div>
            <form [formGroup]="userFormgroup">
                <h2 style="text-align: center;">Create Your Own Card</h2>
                <hr>
                <p style="color: #F44336;"><mat-icon style="font-size:1rem;">error</mat-icon> Every fields are required for create the Card</p>
                <div class="row">
                    <div class="col-md-2 col-sm-2 col-2" style="padding-right: 0px;">
                        <img [src]="flagUrl+selFlag" style="width: 70%; min-height: 50px;" />
                    </div>
                    <div class="col-md-3 col-sm-3 col-3" style="padding-left: 0px;padding-right: 0px;">
                        <input class="form-control" readonly type="text" placeholder="Country code" formControlName="selectedCountry">
                    </div>
                    <div class="col-md-7 col-sm-7 col-7" style="padding-left: 0px;">
                        <div class="form-group">
                            <mat-select [(value)]="selected" *ngIf="countries.length>0" (selectionChange)="selCountryCode()" class="form-control" formControlName="Country_code">
                                <mat-option value="{{country.countries_isd_code}}" *ngFor="let country of countries">{{country.countries_name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control" oninput="this.value=this.value.replace(/[^0-9]/g,'');" fxFlex="100" type="tel" placeholder="Contact No" formControlName="Contact_No">
                </div>
                <!-- [attr.readonly]="!topValidUser" (keyup)="contactMadeValid()" -->
                <!-- <div [hidden]="!otpSent || !topValidUser">
                    <mat-form-field>
                        <mat-label>OTP *</mat-label>
                        <input  matInput class="inputForm" (change)="checkValidOTP()" fxFlex="100" type="tel" placeholder="OTP" formControlName="currentOTP">
                        <div class="pull-right">
                            <button mat-raised-button color="primary" type="button" (click)="checkValidContact()">
                                <span *ngIf="counter>0">{{counter}} remain</span><span *ngIf="counter=='0'">Resend</span>
                            </button>
                        </div>
                    </mat-form-field>
                    <small style="color: red;">OTP sent your entered contact No. please, enter and verify</small>
                </div>
                <div [hidden]="topValidUser"> -->
                    <div class="form-group" style="position: relative;">
                        <input class="form-control"  fxFlex="100" type="{{passwordType}}" placeholder="Password" formControlName="Password">
                        <mat-icon (click)="changeType()" *ngIf="passwordType=='password'" style="position: absolute;
                        z-index: 9999;
                        right: 10px;
                        top: 10px;">visibility</mat-icon>
                        <mat-icon (click)="changeType()" *ngIf="passwordType!=='password'" style="position: absolute;
                        z-index: 9999;
                        right: 10px;
                        top: 10px;">visibility_off</mat-icon>
                    </div>
                    <div class="form-group">
                        <input class="form-control"  fxFlex="100" type="text" placeholder="Name" formControlName="Name">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="email"  fxFlex="100"  placeholder="Email Id" formControlName="Email_Id">
                    </div>
                    <div class="form-group">
                        <textarea class="form-control"  fxFlex="100" placeholder="Address" formControlName="Address"></textarea>
                    </div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="switch1" value="1" formControlName="TermCondition">
                        <label class="custom-control-label" for="switch1">I agree T&C and accept it.</label>
                    </div>
                    <div class="text-center" style="margin-top: 10px;"></div>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="text-center" style="padding-top: 15px;">
                        <button mat-raised-button color="primary" style="float: right;" [disabled]="!userFormgroup.valid || inProgress" (click)="saveRecord()">
                            <mat-icon>save</mat-icon> Submit
                        </button>
                        <button (click)="registerNow('login')" style="float: left;" [disabled]="inProgress" color="primary" mat-raised-button>Login Now</button>
                        <div style="clear: both;"></div>
                    </div>
                <!-- </div> -->
                <!-- <div *ngIf="topValidUser && !otpSent" style="text-align: center;">
                    <button type="button" mat-raised-button [disabled]="invalidContact" (click)="checkValidContact()" color="primary">Send OTP <mat-icon>send</mat-icon></button>
                </div> -->
            </form>
        </div>
        <div style="clear: both;">&nbsp;</div>
        <!-- <div style="margin:10px;"></div> -->
        <!-- <mat-divider></mat-divider> -->
        <!-- <div style="margin:10px;"></div> -->
        <!-- <div style="font-weight: bold; color: #ffffff;" class="text-center">Existing User ? <button (click)="registerNow('login')"  color="primary" mat-raised-button>Login Now</button></div> -->
</div>
</div>
</div>