<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-8 offset-md-2">
        <h2>My Profile</h2>
        <mat-divider></mat-divider>
    </div>    
    <div class="col-md-8 offset-md-2">
        <mat-card class="mat-card-background">
            <form [formGroup]="userFormgroup">
                <div class="row">
                <!-- <div class="form-group col-md-4" *ngIf="userRole=='Franchisee'"> 
                    <label>Display Name*</label>
                    <input class="form-control" type="text" placeholder="company_name" formControlName="company_name">
                </div>
                <div class="form-group col-md-2" *ngIf="userRole=='Franchisee'"> 
                    <label>Theme Color*</label>
                    <input class="form-control" type="color" formControlName="theme_color">
                </div>
                <div class="form-group col-md-3 pl-0" *ngIf="userRole=='Franchisee'"> 
                    <label>Franchisee Logo*</label>
                    <div class="form-group">
                        <label class="upload-label" for="file"><mat-icon>add_photo_alternate</mat-icon><div>Franchisee Logo</div></label>
                        <input id="file" type="file" class="form-control" (change)="onFileChange($event)">
                        <img *ngIf="franchisee" [src]="franchisee" alt="Logo" style="max-height: 100px;">
                        <input class="form-control" type="hidden" formControlName="franchisee_logo">
                    </div>
                </div>
                <div class="form-group col-md-3 pl-0 pr-0" *ngIf="userRole=='Franchisee'"> 
                    <label>Login Background*</label>
                    <div class="form-group">
                        <label class="upload-label" for="background"><mat-icon>add_photo_alternate</mat-icon><div>Login Background</div></label>
                        <input id="background" type="file" class="form-control" (change)="onFileBackground($event)">
                        <img *ngIf="background" [src]="background" alt="Logo" style="max-height: 100px;">
                        <input class="form-control" type="hidden" formControlName="background">
                    </div>
                </div> -->
                <div class="form-group col-md-4">
                    <label>Name*</label>
                    <input class="form-control" type="text" placeholder="Name" formControlName="Name">
                </div>
                <div class="form-group col-md-4">
                    <label>Contact No*</label>
                    <input class="form-control" type="tel" placeholder="Contact No" formControlName="Contact_No">
                </div>           
                <div class="form-group col-md-4">
                    <label>Email Id</label>
                    <input type="email" class="form-control"  placeholder="Email Id" formControlName="Email_Id" required>
                </div>
                <div class="form-group col-md-8">
                    <label>Address</label>
                    <textarea class="form-control" placeholder="Address" formControlName="Address"></textarea>
                </div>
                <div class="form-group col-md-4">
                    <label>City*</label>
                    <input class="form-control" type="text" placeholder="City" formControlName="City">
                </div> 
                <div class="form-group col-md-12 text-right">
                    <button mat-raised-button color="primary" [disabled]="!userFormgroup.valid" (click)="saveRecord()">
                        <mat-icon>save</mat-icon>Update<mat-icon>forward</mat-icon>
                    </button>
                </div>
                </div>
            </form>   
        </mat-card>
    </div>
    <div style="clear: both;"></div>
</div>